.prompt-editor {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 1.5rem;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
 
}

.prompt-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.prompt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.prompt-header h3 {
  color: var(--text-color);
  margin: 0;
}

.prompt-types {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.prompt-type-btn {
  padding: 0.5rem 1rem;
  border-radius: var(--radius);
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: var(--transition);
}

.prompt-type-btn.active {
  background: var(--primary-color);
  border-color: var(--primary-color);
   color: var(--text-color);
}
.prompt-type-btn:hover {
    background: var(--primary-color);
    border-color: var(--primary-color);
     color: var(--text-color);
}

.editor-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.prompt-input {
  width: calc(100% - 2rem);
  min-height: 100px;
  padding: 1rem;
  background: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  color: var(--text-color);
  font-size: 1rem;
  resize: vertical;
}

.generate-btn-prompt-editor {
  align-self: flex-end;
  padding: 0.5rem 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}

.generate-btn-prompt-editor:hover:not(:disabled) {
  background: var(--primary-dark);
}

.generate-btn-prompt-editor:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.output-section {
  background: transparent;

  
}

.output-section h3 {
  margin-bottom: 10px;
  margin-top:0;
  color: var(--text-color);
}

/* ReactQuill Editor Customization */
.quill {
  background: var(--input-bg);
  border-radius: var(--radius);
  border: 1px solid var(--border-color);
}

/* Add toolbar styles */
.ql-toolbar {
  border: none !important;
  border-bottom: 1px solid var(--border-color) !important;
  background: var(--bg-color);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

/* Style toolbar buttons */
.ql-toolbar button {
  color: var(--text-color) !important;
}

.ql-toolbar button:hover {
  color: var(--primary-color) !important;
}

/* Style toolbar button SVG icons */
.ql-toolbar button svg {
  stroke: currentColor;
}

.ql-toolbar .ql-stroke {
  stroke: var(--text-color);
}

.ql-toolbar .ql-fill {
  fill: var(--text-color);
}

.ql-toolbar button:hover .ql-stroke {
  stroke: var(--primary-color);
}

.ql-toolbar button:hover .ql-fill {
  fill: var(--primary-color);
}

/* Style active state */
.ql-toolbar button.ql-active {
  color: var(--primary-color) !important;
}

.ql-toolbar button.ql-active .ql-stroke {
  stroke: var(--primary-color);
}

.ql-toolbar button.ql-active .ql-fill {
  fill: var(--primary-color);
}

.ql-container {
  border: none !important;
  font-size: 1rem;
  color: var(--text-color);
}

.ql-editor {
  height: 220px;
  padding: 0.5rem;
}

.placeholder-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  
}

.placeholder-dropdown {
  width: 200px;
  padding: 8px;
 
  border-radius: 4px;
  background: var(--input-bg);
  font-size: 14px;
  
}

.placeholder-dropdown:focus {
  outline: none;
  border-color: #0066cc;
}

.output-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.prompt-editor-save-btn,
.prompt-editor-done-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.prompt-editor-save-btn {
  background-color: #4CAF50;
  color: white;
}

.prompt-editor-done-btn {
  background-color: #2196F3;
  color: white;
}

.prompt-editor-save-btn:hover,
.prompt-editor-done-btn:hover {
  opacity: 0.9;
}

.sparkles-icon {
  
  vertical-align: middle;
}

.previously-generated {
  font-size: 0.8em;
  color: #666;
  font-style: italic;
  margin-left: 8px;
} 

