.profile-container {
    max-width: 900px;
    margin: 2rem auto;
    background-color: #252542;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .profile-header {
    background: #252542;
    border-radius: 12px ;
    overflow: hidden;
    margin-bottom: 15px !important;
  }
  
  .cover-image {
    height: 140px;
    background: linear-gradient(135deg, #0077b5 0%, #00a0dc 100%);
    position: relative;
  }
  
  .profile-image {
    position: absolute;
    bottom: -50px;
    left: 24px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 4px solid white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background: white;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-info {
    padding: 1.5rem 2rem 2rem;
    margin-top: 50px;
  }
  
  .profile-info h1 {
    font-size: 1.75rem;
    font-weight: 700;
    background: linear-gradient(45deg, #4a90e2, #67b1f7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.5rem;
  }
  
  .profile-info h2 {
    font-size: 1.25rem;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  .headline{
    margin-left: 0 !important;
  }
  
  .location-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #ffffff;
    font-size: 0.875rem;
  }
  
  .location-info svg {
    width: 16px;
    height: 16px;
  }
  
  .section {
    background-color: #252542;
    margin-top: 1rem;
    padding: 0 2rem 1.5rem 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    max-height: 400px;
    overflow-y: scroll;
    position: relative; 
  }
  
  .section h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 1.5rem;
    margin-top: 0;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    position: sticky;
    top: 0;
    background-color: #252542;
    padding: 1rem 0;
   
  }
  
  .section h3 svg {
    color: #0077b5;
  }
  
  .experience-item {
    display: flex;
    gap: 1rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 0.5px solid #ccc;
  }
  
  .experience-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .prospect-company-logo {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8fafc;
  }
  
  .prospect-company-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .job-info {
    flex: 1;
  }
  
  .job-info h4 {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
  
  .job-info .company {
    color: #ffffff;
    font-size: 0.9375rem;
  }
  
  .job-info .duration {
    color: #ffffff;
    font-size: 0.875rem;
    margin: 0.25rem 0;
  }
  
  .job-info .description {
    color: #ffffff;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin-top: 0.75rem;
  }
  
  .skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
  }
  
  .skill-tag {
    background-color: #3a3a5f;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    color: #ccc;
    border: none;
  }
  
  .linkedin-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 1rem;
    background: #0077b5;
    color: white;
    border-radius: 6px;
    font-weight: 500;
    font-size: 0.875rem;
    text-decoration: none;
    transition: all 0.2s ease;
  }
  
  .linkedin-button:hover {
    background: #005885;
  }
  
  @media (max-width: 640px) {
    .profile-container {
      margin: 0;
      border-radius: 0;
    }
  
    .profile-image {
      width: 100px;
      height: 100px;
      left: 16px;
    }
  
    .profile-info {
      padding: 1rem;
    }
  
    .section {
      padding: 1rem;
    }
  
    .skills-grid {
      grid-template-columns: 1fr;
    }
  }

  .buttons-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
    justify-content: flex-start;
  }
  
  .buttons-section button {
    flex: 1 1 auto;
    min-width: 150px;
    max-width: calc(33.33% - 10px);
    white-space: nowrap;
  }
  
  /* For smaller screens */
  @media (max-width: 768px) {
    .buttons-section button {
      min-width: calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }
  
  /* For very small screens */
  @media (max-width: 480px) {
    .buttons-section button {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .name-section {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
  }

  .name-section h1 {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #00a0dc;
    min-width: 160px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 4px;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #fff;
    border: none;
    background: #00a0dc;
    width: 100%;
    text-align: left;
    cursor: pointer;
    border-radius: 4px;
  }

  .dropdown-item:hover {
    background-color: #28b8ed;
  }

  .more-options {
    padding: 8px 16px;
    background-color: transparent;
    border: 1px solid #00a0dc;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    font-size: 0.9rem;
  }

  .more-options:hover {
    background-color: #00a0dc;
    border: 1px solid #00a0dc;
  }

  .research-results-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #252542;
    border-radius: 8px;
    color: #ffffff;
    position: relative;
  }

  .result-block {
    margin-bottom: 0;
  }

  .result-block h3 {
    margin-bottom: 16px;
    color: #00a0dc;
    font-size: 1.25rem;
    margin-top: 0 !important;
  }

  .research-content {
    white-space: pre-wrap;
    line-height: 1.6;
    
  }

  .research-content p {
    margin-bottom: 16px;
    color: #fff;
  }

  .button-loading-dots {
    display: inline-block;
    position: relative;
    width: 16px;
  }
  
  .button-loading-dots::after {
    content: '...';
    position: absolute;
    animation: loadingDots 1.5s infinite;
    width: 16px;
    text-align: left;
  }
  @keyframes loadingDots {
    0% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
    100% { content: '.'; }
  }
  
.button-researching {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
  
  .button-spinner {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #0077b5;
    border-radius: 50%;
    margin-right: 8px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes dots {
    0%, 20% { content: '.'; }
    40% { content: '..'; }
    60%, 100% { content: '...'; }
  }
  
  /* Tabs Styling */
.research-tabs {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem;
  margin: -20px -20px 1rem -20px;
  border-bottom: 1px solid #3a3a5f;
  background-color: #252542;
  position: sticky;
  top: 0;
  z-index: 100;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

.research-tabs::-webkit-scrollbar {
  display: none;
}

.research-tab {
  padding: 12px 4px;
  background-color: transparent;
  border: none;
  color: #8e8ea0;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  position: relative;
  margin: 0 8px;
}

.research-tab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0077ff;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.research-tab:hover {
  color: #ffffff;
}

.research-tab.active {
  color: #ffffff;
}

.research-tab.active::after {
  transform: scaleX(1);
}

.research-tab-content {
  padding: 1.5rem 0;
  padding-bottom: 0;
  overflow-y: auto;
  position: relative;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 1rem;
}
.contact-details-title{
  margin-bottom: 2rem;
  margin-top: 0 !important;
  margin-left:0 !important;
  color: #fff;  
  font-size: 1.5rem;
  font-weight: 600; 
  text-align: left;
  padding:0;
}

.contact-details p {
  margin: 10px 0;
}

.custom-button.enrich-contact,
.custom-button.prospect-research,
.custom-button.save-to-onepgr-btn,
.custom-button.company-research,
.custom-button.signal-automation,.custom-button.find-peers{
  padding: 10px ;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  color: #4a90e2;
  border: 1px solid #4a90e2;
  transition: all 0.3s ease;
  
}
.custom-button.prospect-research:hover,
.custom-button.company-research:hover,
.custom-button.signal-automation:hover,
.custom-button.find-peers:hover,
.custom-button.save-to-onepgr-btn:hover{
  background: linear-gradient(135deg, #4a90e2, #67b1f7);
  color: #fff;
}
.custom-button.save-to-onepgr-btn{
  max-width: 190px !important;
}
.custom-button.close-popup{
  position: absolute !important;
  top: 0px !important;
  right: 10px !important;
  border-radius: 50% !important;
  font-size: 1.2rem !important;
  background-color: none !important;
  border: none !important;
 
}

.prospect-enrich-contact-btn {
  background: #4caf50;
  color: white;
  border: none !important;
  border-radius: 4px !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  transition: all 0.3s ease !important;
  margin-top: 10px !important;
}
.prospect-enrich-contact-btn:hover{
  background: #45a049 !important;
  color: #fff !important;
}



.custom-button.close-popup {
  background: transparent;
  color: #666 !important;
}

.custom-button.close-popup:hover {
  color: rgb(208, 65, 65) !important;

}

.custom-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
.contact-details .contact-details-input {
  margin-bottom: 25px !important;
}
.contact-details-input{
  position: relative;
  margin-bottom: 15px !important;
}

.contact-details-input label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  position: absolute;
  color: #ccc;
  top: -15px !important;
  left: 10px;
  background-color: #333;
  padding: 0 5px;
}

.contact-details .form-control {
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333; /* Light gray background for read-only fields */
  color: #ccc; /* Dark text color */
  cursor: not-allowed; /* Show not-allowed cursor for read-only fields */
}

.button-saving {
  display: flex;
  align-items: center;
  gap: 8px;
}

.button-spinner {
  border: 2px solid #f3f3f3; /* Light gray border */
  border-top: 2px solid #3498db; /* Blue border for spinner */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-loading-dots::after {
  content: '';
  animation: dots 1s infinite;
}

@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60%,
  100% {
    content: '...';
  }
}

.expandable-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
}

.expandable-content.expanded {
  max-height: 5000px; /* Adjust this value based on your content */
  transition: max-height 0.5s ease-in;
  margin-bottom: 16px; /* Add space between content and show more button */
}

.show-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #0077b5;
  font-weight: 600;
  
}



.buttons-section {
  margin-top: 16px;
}

.linkedin-view-icon {
  color: #4a90e2;
  cursor: pointer;
  transition: opacity 0.2s;
  margin-bottom: -5px;
}

.linkedin-view-icon:hover {
  opacity: 0.8;
}
.name-with-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}
.linkedin-profile-contact-icons{
  margin-left: auto;
}



.enrich-icon-tooltip-wrapper {
  position: relative;
  display: inline-block;
 
  margin-top: 20px;
}

.enrich-wand-icon {
  color: #ccc;
  transition: color 0.3s ease;
}

.enrich-wand-icon:hover {
  color: #4a90e2;
}

.enrich-icon-tooltip {
  width: 120px; /* Set fixed width */
  white-space: normal; /* Allow text to wrap */
  line-height: 1.2; /* Improve readability for wrapped text */
  min-height: fit-content; /* Adjust height based on content */
  background-color: #000;
  color: #fff !important;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 999999 !important;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  pointer-events: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  letter-spacing: 0.5px;
  text-shadow: none !important;
  visibility: hidden;
}

.enrich-icon-tooltip-wrapper:hover .enrich-icon-tooltip {
  visibility: visible;
  opacity: 1;
  overflow: visible !important;
}

.enrich-icon-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
.video-message-tooltip{
  left: -450% !important;
  right: 0 !important;
  transform: none !important;
  top: -240% !important;
}
.video-message-tooltip::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 60%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.enrich-button-profile{
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
}

.enrich-wand-icon.disabled {
  pointer-events: none;
}
.save-to-onepgr-notes-prospect,.save-to-onepgr{
  background-color: #4a90e2 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  transition: all 0.3s ease !important;
  margin-top: 10px !important;
}
.save-to-onepgr-notes-prospect:hover,.save-to-onepgr:hover{
  background-color: #307dd5 !important;
  color: #fff !important;
}

.peers-table th:first-child, .peers-table td:first-child {
  width: 30%;  /* Checkbox column - small */
  text-align: left;
}
.peers-table th:nth-child(2),
.peers-table td:nth-child(2) {
  width: 30%;  /* Name column - larger */
}
 .peers-table th:nth-child(3),
.peers-table td:nth-child(3) {
  width: 25%;  /* Name column - larger */
}
.peers-table th:nth-child(4),
.peers-table td:nth-child(4) {
  width: 15%;  /* Name column - larger */
  
}
.peers-table td:nth-child(4){
  text-align: center;
}
.peers-table th{
background-color: #1a1a33 !important;
}
.bottom-show-less{
  margin-top: 15px;
  
}
.research-content-notes-btn-container{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;

}


.signal-section {
  padding: 0;
  margin-top: 0;
}

.posts-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.post-item {
  background: #1a1a33;
  border: none;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.post-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-header {
  display: flex;
  flex-direction: column;
 
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
.post-header h4{
  color: #4a90e2;
  margin-bottom: 0 !important;
}

.post-time {
  color: #6b7280;
  font-size: 0.875rem;
}

.post-preview-text {
  color: #ccc;
  margin: 0.5rem 0;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.post-metadata {
  display: flex;
  gap: 1rem;
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.post-details {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.post-images {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.post-images img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.view-on-linkedin {
  display: inline-block;
  color: #0077b5;
  text-decoration: none;
  margin-top: 0.5rem;
}

.view-on-linkedin:hover {
  text-decoration: underline;
}

.posts-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem 0;
}
.posts-pagination-button{
  background-color: #1a1a33 !important;
}
.posts-pagination-button:hover{
  background-color: #131323 !important;
}
.posts-pagination-button:disabled{
  background-color: #1a1a33 !important;
  color: #6b7280 !important;
}
.posts-pagination-button:disabled:hover{
  background-color: #1a1a33 !important;
  color: #6b7280 !important;
}

.post-expand-button{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 !important;
  margin-top: 10px !important;
}
/* 
.pagination-info {
  color: #6b7280;
  font-size: 0.875rem;
} */



.job-card {
  background: #1a1a33;
  border: none;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.job-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: #4a90e2;
  margin: 0;
}

.job-time {
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
}

.job-meta {
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
}

.job-company,
.job-location,
.job-applicants {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #ffffff;
  opacity: 0.8;
  font-size: 14px;
}

.job-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.job-tag {
  background: #3a3a5f;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
}

.job-description {
  color: #ffffff;
  opacity: 0.9;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.job-actions {
  display: flex;
  gap: 12px;
}

.apply-button,
.view-linkedin-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s;
}

.apply-button {
  background: linear-gradient(135deg, #4a90e2, #67b1f7);
  color: white;
}

.apply-button:hover {
  background: linear-gradient(135deg, #3a80d2, #57a1e7);
}

.view-linkedin-button {
  background: #3a3a5f;
  color: #ffffff;
}

.view-linkedin-button:hover {
  background: #4a4a6f;
}

.no-jobs-message {
  text-align: center;
  padding: 40px;
  color: #ffffff;
  opacity: 0.6;
  font-size: 16px;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.image-modal-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-modal-button {
  position: absolute;
  top: -40px;
  right: -40px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  padding: 10px;
}

.close-modal-button:hover {
  opacity: 0.8;
}

.post-images img {
  transition: transform 0.2s;
}

.post-images img:hover {
  transform: scale(1.02);
}

.research-actions {
  display: flex;
  justify-content: flex-end;
}

.run-prompt-btn {
  background: linear-gradient(45deg, #4f9eff, #7c4fff, #4f9eff);
  background-size: 200% 200%;
  color: white;
  border: none;
  padding: 10px 1rem;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
}



.run-prompt-btn:hover {
  background: linear-gradient(45deg, #4f9eff, #7c4fff, #4f9eff);
  background-size: 200% 200%;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  animation: gradientMove 3s ease infinite;
}
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.run-prompt-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ai-sparkle {
  font-size: 1.1rem;
  animation: sparkle 1.5s infinite;
  color: #7c4fff;
}

@keyframes sparkle {
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
}

.prompt-editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.prompt-editor-content {
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  overflow-y: auto;
  position: relative;
}

.close-prompt-editor {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color:#666;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;
  z-index: 1;
}

.close-prompt-editor:hover {
  color: #fff;
}

.funding-and-acquisition-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}

.company-funding-overview {
  background: #1a1a2e;
  border-radius: 8px;
  padding: 1.5rem;
}

.overview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.overview-item {
  background: #252542;
  padding: 1rem;
  border-radius: 6px;
  color: #ccc;
}

.overview-item strong {
  color: #4a90e2;
  display: block;
  margin-bottom: 0.5rem;
}

.overview-item.ipo-details {
  grid-column: 1 / -1;
  background: linear-gradient(135deg, #1a1a2e, #252542);
  border: 1px solid #4a90e2;
}

.funding-rounds {
  background: #1a1a2e;
  border-radius: 8px;
  padding: 1.5rem;
}

.funding-round-item {
  background: #252542;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.2s ease;
}

.funding-round-item:hover {
  transform: translateY(-2px);
}

.round-header {
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #333;
  color: #4a90e2;
}

.round-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #ccc;
}

.round-details strong {
  color: #4a90e2;
}

.advisors-section {
  background: #1a1a2e;
  border-radius: 8px;
  padding: 1.5rem;
}

.advisors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.advisor-item {
  background: #252542;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.advisor-item strong {
  color: #4a90e2;
}

.advisor-item span {
  color: #ccc;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.funding-announcement-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.funding-announcement-item {
  background: #1a1a2e;
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid #252542;
}

.funding-announcement-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #252542;
}

.event-header h3 {
  color: #4a90e2;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

.event-date {
  color: #6b7280;
  font-size: 0.9rem;
}

.event-details {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.event-detail {
  color: #ccc;
  line-height: 1.5;
}

.event-detail strong {
  color: #4a90e2;
  margin-right: 0.5rem;
  font-weight: 500;
}

.event-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a90e2;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.5rem;
  transition: all 0.2s ease;
  padding: 0.5rem 0;
}

.event-link:hover {
  color: #67b1f7;
}

.event-link:hover svg {
  transform: translateX(2px);
}

.event-link svg {
  transition: transform 0.2s ease;
}