.sequence-list-container {
  overflow: auto !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sequence-table {
  overflow: auto !important;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #ffffff;
  border-radius: 10px !important;
  table-layout: fixed;  /* Ensures table uses fixed layout for better control */
}

.sequence-table th,
.sequence-table td {
  border: 1px solid #444;
  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sequence-table th {
  background-color: #252542;
  font-weight: bold;
  cursor: pointer;
}

.sequence-table th:hover {
  background-color: #313155;
}

.sequence-table tr {
  background-color: #1a1a2e;
}

/* Column width specifications */
.sequence-table th:first-child,
.sequence-table td:first-child {
  width: 5%;  /* Checkbox column - small */
  text-align: center;
}

.sequence-table th:nth-child(2),
.sequence-table td:nth-child(2) {
  width: 30%;  /* Name column - larger */
}

.sequence-table th:nth-child(3),
.sequence-table td:nth-child(3),
.sequence-table th:nth-child(4),
.sequence-table td:nth-child(4) {
  width: 10%;  /* Type, Steps, and Date columns - equal */
}

.sequence-table th:nth-child(5),
.sequence-table td:nth-child(5) {
  width: 15%;  /* Adjusting width for Date column */
}

.sequence-table th:nth-child(6),
.sequence-table td:nth-child(6) {
  width: 10%;  /* View column - new width */
}

/* Styling the View button inside the table */
.sequence-table td button {
  padding: 5px 10px;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.sequence-table td button:hover {
  background-color: #3983dd;
}

.sequence-table input[type="checkbox"] {
  cursor: pointer;
}

.sequence-table .selected-row {
  background-color: #2c3e50;
}

.sequence-table .selected-row:hover {
  background-color: #34495e;
}

.filter-input {
  margin-bottom: 10px;
  padding: 5px;
  width: calc(100% - 12px) !important;
  background-color: #252542;
  color: #ffffff;
  border: 1px solid #444;
  border-radius: 4px;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  padding: 10px 0;

}

.pagination-buttons {
  display: flex;
  align-items: center;
}

.pagination-button {
  background-color: #252542;
  color: #ccc;
  border: none;
  padding: 5px 5px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  color: #ccc;
}

.add-to-sequence-button {
  padding: 5px 15px;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  
  margin-left: auto !important;
}

.add-to-sequence-button:hover {
  background-color: #3983dd;
}

.add-to-sequence-button svg {
  margin-right: 5px;
}

.sequencelist-table-footer {
  padding: 12px 15px;
  background-color: #1a1a2e;
  border-top: 1px solid #444;
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 0 0 10px 10px;
}
.sequence-link{
  text-decoration: none ;
  color: #ffffff;
}
.sequence-link:hover{
  color: #4a90e2;
  text-decoration: underline;
}