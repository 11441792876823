.people-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .people-table th,
  .people-table td {
  
    padding: 12px;
    vertical-align: top;
    text-align: left;
  }
  
  
  
  .details-list,
  .location-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .details-list li,
  .location-list li {
    margin-bottom: 6px;
  }
  
  .details-list li strong,
  .location-list li strong {
    width: 120px;
    display: inline-block;
  }
  