.chat-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  background-color: #1a202c;
  color: #ccc;
  overflow: hidden;
}

.chat-container h2 {
  text-align: left;
  margin-left: 20px;
  color: #ccc;
  z-index: 10000;
}

.message-area {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding:  1rem;
  display: flex;
  flex-direction: column;
  width: 65%;
  height:75vh;
  margin: 0 auto;
  margin-top: 60px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  /* background-color: #155724; */
}

.message-container {
  display: flex;
  flex-direction: column;
  max-width: 95%;
  margin-bottom: 1.5rem;
  /* margin-left: 20px; */
}

.user-container {
  align-self: flex-start;
}

.system-container {
  align-self: flex-start;
  
}

.message {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-align: left;
  position: relative;
 
}


.user-container {
  align-self: flex-start;
}

.system-container {
  align-self: flex-start;
}


/* .user-message p{
  margin-top: auto;
  
} */


/* .system-message {
  background-color: #2d3748;
 
} */
/* .ai-message{
  background-color: #2d3748;
 
} */
 .system-message{
  width: 100%;
  background-color: transparent !important;
  margin-right: -35px;
  margin-top: 0px;
 }

.system-message#welcome-message {
  background-color: transparent;
  padding: 0 15px;
  margin-bottom: 0;
}
.chat-form {
  position: relative;
  padding: 1rem;
  width: 65%;
  margin: 0 auto;
  
  /* background-color: aqua; */
}

.chat-input {
  width: calc(100% - 3.5rem);
  min-height: 40px;
  max-height: 200px;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  background-color: #4a5568;
  color: white;
  outline: none;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;
  line-height: 1.5;
  transition: border-radius 0.3s ease;
}

.chat-input::-webkit-scrollbar {
  width: 6px;
}

.chat-input::-webkit-scrollbar-thumb {
  background-color: #718096;
  border-radius: 3px;
}

.chat-button {
  position: absolute;
  right: 2rem;
  bottom: 2.3rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}



.chat-input.expanded {
  border-radius: 20px;
}

.scroll-button {
  position: fixed;
  bottom: 8rem;
  right: 50%;
  padding: 0.2rem;
  border-radius: 9999px;
  background-color: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  text-align: center;
}

.scroll-button:hover {
  background-color: #4a5568;
}

.chat-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.chat-th {
  background-color: #4a5568;
  padding: 0.5rem;
  text-align: left;
}

.chat-td {
  background-color: #2d3748;
  padding: 0.5rem;
  border-bottom: 1px solid #4a5568;
}

.typing-indicator {
  display: inline-block;
  margin-left: 25px;
}

.typing-indicator::after {
  content: '...';
  animation: ellipsis 1.5s infinite;
}

@keyframes ellipsis {
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
}

.review-prompt {
  background-color: #2d3748;
  border-radius: 8px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  align-self: flex-start;
  width: 70%;
  margin-left: 50px !important;
}

.review-prompt h2 {
  color: #ccc;
  margin-bottom: 10px;
  margin-left: auto;

  
  font-size: 1.5rem;
}

.review-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.review-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-item label {
  color: #a0aec0;
  margin-right: 10px;
  flex: 1;
}

.review-item input {
  background-color: #4a5568;
  color: #e2e8f0;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  flex: 2;
}

.review-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.review-actions button {
  background-color: #4a5568;
  color: #e2e8f0;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.review-actions button:hover {
  background-color: #2d3748;
}

.editable-message {
  background-color: #2d3748;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  width:calc(100% - 20px);
 
}

.editable-textarea {
  width: calc(100% - 16px);
  background-color: #4a5568;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
  resize: none;
  min-height: 60px;
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cancel-button, .send-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.send-button {
  background-color: #4299e1;
  color: white;
}
.send-button:hover {
  background-color: #307dd5;
}

/* .edit-button {
  background-color: transparent;
  border: none;
  color: #a0aec0;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 0px !important;
  bottom: 5px;
  font-size: 0.8rem;
  opacity: 0;
  transition: opacity 0.3s;
} */

.user-message:hover .edit-button {
  opacity: 1;
}

.edit-button:hover {
  text-decoration: underline;
}

.message-list {
  flex: 1 1 auto;
  min-height: 0;
  padding-bottom: 20px;
}

/* review prompt*/


.review-prompt h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.score-alert {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.score-alert h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.good-score {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.low-score {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}

.review-item {
  margin-bottom: 10px;
}

.review-item label {
  display: block;
  margin-bottom: 5px;
}

.review-item input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.review-actions {
  margin-top: 15px;
}

.submit-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.submit-button {
  background-color: #007bff !important;
  color: white;
}

.submit-button:hover {
  background-color: #0056b3 !important;
}

.submit-button.disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}




.chat-input.disabled {
  background-color: #f0f0f0;
  color: #888;
}

.chat-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* alert css*/

.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-heading {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.list-disc {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.example-prompt-list li{
  margin-bottom: 0.5rem;
}


/* animation */

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thinking-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 30px;
  margin-left: 25px;
  width: fit-content;
}

.thinking-indicator .brain {
  width: 30px;
  height: 30px;
  background-color: #0c43a2;
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z"/><path d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z"/></svg>') center / contain no-repeat;
  animation: pulse 1.5s infinite ease-in-out, rotate 10s linear infinite;
}

.thinking-indicator .text {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.edit-button {
  position: absolute;
  bottom: -13px;
  right: -13px;
  background-color: #21201c; /* Semi-transparent background */
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #ccc;
  transition: opacity 0.3s, color 0.3s;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: all 0.3s ease;
  width: 24px; /* Width of the icon */
  white-space: nowrap;
  overflow: hidden;

  border-radius: 5px;
}
.edit-button:hover {
  color: #ccc;
  width: 60px; 
  text-decoration: none;

}
.edit-icon {
  display: inline-block;
  transition: transform 0.3s ease;
  font-size: 12px;
}
.edit-button:hover .edit-icon {
  transform: translateX(-5px);
  margin-left: 5px;
}
.edit-text {
  margin-left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.edit-button:hover .edit-text {
  opacity: 1;
}

@media (max-width: 850px) {
  .message-area {
    width: calc(100% - 1rem) ;/* Full width */
    margin-top: 80px; 
    padding: 0.5rem; 
  }
  .chat-form {
    width: calc(100% - 2rem)
  }
}
.example-prompt{
  padding-left: 0 !important; 
  margin-left: 0px !important; 
  text-align: left !important;
}


.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  
}

