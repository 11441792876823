.generated-content {
    flex: 1;
    padding: 1.5rem;
    overflow-y: auto;
    color: var(--text-color);
}

.title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.section-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.content-paragraph {
    font-size: 1rem;
    line-height: 1.8;
    margin: 1rem 0;
    white-space: pre-wrap;
}