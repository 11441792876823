

.search-bar .search-input{
    width: calc(100% - 20px);
   margin-top: 10px;
   margin-bottom: 0;
   background-color: #252542;
   padding: 10px;
   border-radius: 4px;
   border: none;
   color: #ccc;
}
.accounts-header{
    color: #ccc;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 0;
}
.accounts-table-header{
    text-align: left !important;
}

.accounts-table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.accounts-table  td:nth-child(1) {
    text-align: left;
}

