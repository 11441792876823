.select-list-drop-time-container {
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .select-list-drop-filter-input {
    margin-bottom: 10px;
    padding: 5px;
    background-color: #2c2c2c;
    color: #ffffff;
    border: 1px solid #444;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;
    width: 20rem;
  }
  
  .filter-input-date-time {
    width: 11rem;
  }
  
  label {
    display: block;
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .next-button {
    /* margin-top: 10px; */
    padding: 8px 16px;
    background-color: #2c2c2c;
    color: #ffffff;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: fit-content;
  }
  
  .next-button:hover {
    background-color: #3e3e3e;
  }
  .view-sequence-report {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .drop-sequence-container{
    float: right;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .date-time-picker-container{
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    
    margin-bottom: 10px;
  }
  .MuiInputBase-root{
    height: 2.5rem;
    color: white !important;
    font-size: 14px !important;
    background-color: #2c2c2c !important;
    border: 1px solid #444 !important;
  }

  