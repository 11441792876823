.linkedinMessagesContainer {
    max-width: 900px;
    max-height: 900px;
    overflow-y: scroll;
    margin: 1rem auto;
    
    background-color: #121010;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .linkedinMessagesHeader {
    padding: 1rem;
    border-bottom: 1px solid #3a3a5f;
  }
  
  .linkedinMessagesTitle {
    font-size: 1.25rem;
    font-weight: 700;
    background: linear-gradient(45deg, #4a90e2, #67b1f7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

  }
  
  .linkedinMessagesThread {
    border-bottom: 1px solid #3a3a5f;
    transition: background-color 0.2s ease;
  }
  
  
  .linkedinMessagesThread:last-child {
    border-bottom: none;
  }
  
  .linkedinMessagesPreview {
    padding: 1rem;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }
  
  .linkedinMessagesAvatar {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: linear-gradient(135deg, #0077b5 0%, #00a0dc 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    flex-shrink: 0;
  }
  
  .linkedinMessagesContent {
    flex: 1;
  }
  
  .linkedinMessagesContentHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    gap: 10px
  }
  .linkedinMessagesSenderInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .linkedinMessagesSenderInfo h3 {
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }
  
  .linkedinMessagesSenderTitle {
    color: #a3a3c2;
    font-size: 0.875rem;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px; 
    position: relative;
    cursor: pointer; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 130px; 
    overflow: hidden;
  }
  
  .linkedinMessagesMetadata {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #a3a3c2;
    font-size: 0.75rem;
  }
  
  .linkedinMessagesPreviewText {
    color: #ffffff;
    font-size: 0.9375rem;
    line-height: 1.5;
    margin-bottom: 0.75rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .linkedinMessagesToggleButton {
    color: #4a90e2;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .linkedinMessagesToggleButton:hover {
    color: #67b1f7;
  }
  
  .linkedinMessagesExpanded {
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
  
  }
  
  .linkedinMessagesBody {
    background-color: #2d2d4f;
    padding: 1.5rem;
    border-radius: 8px;
    color: #ffffff;
  }
  
  .linkedinMessagesBody a {
    color: #4a90e2;
    text-decoration: none;
  }
  
  .linkedinMessagesBody a:hover {
    text-decoration: underline;
  }
  
  .linkedinMessagesFooter {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #3a3a5f;
    color: #a3a3c2;
    font-size: 0.875rem;
  }
  
  .linkedinMessagesProfileLink {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #4a90e2;
    text-decoration: none;
    margin-top: 0.5rem;
  }
  
  .linkedinMessagesProfileLink:hover {
    color: #67b1f7;
  }
  
  .linkedinMessagesReadStatus {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #4caf50;
    font-size: 0.75rem;
  }
  
  @media (max-width: 640px) {
    .linkedinMessagesContainer {
      margin: 0;
      border-radius: 0;
    }
  
    .linkedinMessagesHeader,
    .linkedinMessagesPreview {
      padding: 1rem;
    }
  
    .linkedinMessagesExpanded {
      padding: 0 1rem 1rem 4rem;
    }
  
    .linkedinMessagesAvatar {
      width: 40px;
      height: 40px;
    }
  }