.fad-container {
  max-width: 1200px;
  margin: 15px auto;
  padding: 2rem;
  background-color: #1a1a2e;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fad-header {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #252542;
}

.fad-company-name {
  font-size: 2rem;
  font-weight: 600;
  background: linear-gradient(45deg, #4a90e2, #67b1f7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.fad-quick-stats {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;

}

.fad-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ccc;
  font-size: 0.95rem;
}

.fad-stat-icon {
  color: #0a66c2;
  font-size: 1.25rem;
}

.fad-website {
  text-decoration: none;
  color: #ccc;
  transition: color 0.2s ease;
}

.fad-website:hover {
  color: #004182;
}

.fad-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.fad-metric-card {
  background-color: #252542;
  border: 1px solid #252542;
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

}

.fad-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.fad-metric-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
 
}

.fad-metric-icon {
  color: #0a66c2;
  font-size: 1.5rem;
}

.fad-metric-title {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.fad-metric-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #ccc;
  margin: 0;
}

.fad-toggle-btn {
  display: block;
  margin: 0 auto 1rem;
  padding: 0.75rem 1.5rem;
  background-color: transparent;
  color: #3498db;

  border: none;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.fad-toggle-btn:hover {
    text-decoration: underline;
    color: #4a90e2;
}

.fad-address-section {
  background-color: #252542;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;
}

.fad-section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
}

.fad-address-text {
  color: #ccc;
  line-height: 1.5;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .fad-container {
    padding: 1rem;
  }

  .fad-company-name {
    font-size: 1.5rem;
  }

  .fad-quick-stats {
    gap: 1rem;
  }

  .fad-metrics-grid {
    grid-template-columns: 1fr;
  }
} 