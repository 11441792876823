.content-composer {
    
    border-radius: 4px;
    padding: 15px;
    background-color: #1c1c1c;
    margin: 10px;
  }
  
  .composer-header {
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    margin-bottom: 15px;
    margin-left: -20px;
    font: 0.8em sans-serif;
  }
  .composer-header-text{
    margin-left: 10px;
  }
  
  .composer-actions {
    display: flex;
    align-items: center;
  }
  
  .action-icon {
    margin-right: 10px;
    cursor: pointer;
  }
  
  .toggle-switch {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .a-b-button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .composer-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .time-offset input {
    width: 50px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #1c1c1c;
    color: #fff;
  }
  
  .subject-line {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .subject-line input {
    flex-grow: 1;
    
    margin-left: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #1c1c1c;
    color: #fff;
  }
  
  .placeholder-dropdown {
    padding: 5px;
  }
  
  /* You may need to adjust some styles for ReactQuill to fit your design */
  .quill {
    background-color: #1c1c1c;
    color: #fff;
    border-radius: 4px ;
  }



  