.LinkedinJobDetailsWrapper {
    max-width: 900px;
    margin: 2rem auto;
    background-color: #121010;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.LinkedinJobDetailsHeading {
    padding: 1rem;
    border-bottom: 1px solid #3a3a5f;
}

.LinkedinJobDetailsTitleBar {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
    margin-left: 5px !important;
}

.LinkedinJobDetailsIcon {
    color: #4a90e2;
}

.LinkedinJobDetailsContent {
    padding: 1.5rem;
}

.LinkedinJobDetailsHeader {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
}

.LinkedinCompanyLogo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: linear-gradient(135deg, #0077b5 0%, #00a0dc 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    flex-shrink: 0;
}

.LinkedinJobMainInfo {
    flex: 1;
}

.LinkedinJobTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

.LinkedinCompanyName {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a3a3c2;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.LinkedinJobLocation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a3a3c2;
    font-size: 0.875rem;
}

.LinkedinJobSection {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: #1a1a2e;
    border-radius: 8px;
}

.LinkedinSectionTitle {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.LinkedinKeyInfoGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.LinkedinKeyInfoItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a3a3c2;
}

.LinkedinCompanyDescription {
    color: #a3a3c2;
    line-height: 1.6;
    max-height: 300px;
    overflow-y: auto;
}

.LinkedinTagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.LinkedinTag {
    background-color: #2d2d4f;
    color: #ffffff;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
}

.LinkedinJobDescription {
    color: #a3a3c2;
    line-height: 1.6;
    white-space: pre-line;
    max-height: 300px;
    overflow-y: auto;
}

.LinkedinApplyButton {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #4a90e2;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.LinkedinApplyButton:hover {
    background-color: #357abd;
}

.LinkedinInlineIcon {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.25rem;
}

@media (max-width: 640px) {
    .LinkedinJobDetailsWrapper {
        margin: 1rem;
        border-radius: 8px;
    }

    .LinkedinJobDetailsContent {
        padding: 1rem;
    }

    .LinkedinKeyInfoGrid {
        grid-template-columns: 1fr;
    }
}