.intent-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    align-items: flex-start;
  }

  .intent-header-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
   
    width: 100%;
  }
  .intent-header-bottom h3 {
  
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  
  .remove-intent-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
    transition: all 0.3s ease;
  }
  
  .remove-intent-button:hover {
    color: #fff;
    background-color: #ff4d4d;
    border-radius: 5px;
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 150px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }


.add-field-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 20px;
    border-radius: 10px;
    background-color: #4a90e2;
    color: white;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    padding: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .add-field-button:hover {
    background-color: #357abd;
  
  }
  
  .add-field-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.5);
  }
  
  .add-field-button svg {
    width: 12px;
    height: 12px;
  }
  .review-cancel-button{
    background-color: #dc3545 !important;
    color: white;
  }
  .review-cancel-button:hover{
    background-color: #c82333 !important;
  }

.toggle-search-button {
  padding: 6px 12px;
  margin-right: 10px;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: white;
 
}

.toggle-search-button:hover {
  background-color: #357abd;
}

/* Container for the entire switch */
.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

/* Switch label styling */
.switch-label {
  font-size: 16px;
  font-weight: 500;
  color: #888;
}

/* The switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 48px !important;
  height: 22px !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

/* Add ON/OFF text */
.slider:after {
  content: "OFF";
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  font-size: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px !important;
  width: 15px !important;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(36px);
}

/* Change text when checked */
input:checked + .slider:after {
  content: "ON";
  left: 5px;
  right: auto;
  color: white;
}

.search-type-toggle {
  margin: 10px 0;
}

.search-type-toggle .toggle-button {
  padding: 8px 16px;
  border: 2px solid #ccc;
  background-color: #f5f5f5;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.search-type-toggle .toggle-button:hover {
  background-color: #e6e6e6;
}

.search-type-toggle .toggle-button[class*="active"] {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  color: white !important;
}
