body {
  background-color: #1a202c;
}
.api-keys-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #2d3748;
    border-radius: 8px;
  }
  
  .api-keys-title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 30px;
    text-align: left;
    margin-top: 0;
  }
  
  .api-provider {
    margin-bottom: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    width: calc(100% - 40px);
  }
  
  .provider-name {
    font-size: 14px;
    margin-bottom: -15px;
    color:#ccc;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #2d3748;
    padding: 3px 5px;
  }
  
  .api-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .api-input {
 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: transparent;
    color:#ccc;
    
  }
  
  .save-button {
    padding: 10px 20px;
    background-color: #4a5568;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: #888;
   
  }
  
  .save-button:hover {
    background-color: #4a5568;
  }
  .save-button.active {
    background-color: #1f4f8f;
    color: white;
  }
  
  .save-button.active:hover {
    background-color: #1f4f8f;
    color: white;
  }
  /* Tooltip styles */
.custom-tooltip {
  max-width: 300px !important;
  word-break: break-word;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
}

/* Ensure the tooltip text is visible */
.custom-tooltip .tooltip-inner {
  padding: 8px;
  font-size: 12px;
  line-height: 1.4;
}

/* Custom arrow styles to match the tooltip */
.custom-tooltip .arrow::before {
  border-top-color: #ced4da !important;
}

/* Style for the info icon */
.info-icon-wrapper {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.info-icon {
  font-size: 16px;
  color:  #fff;;
  
}

/* Optional: Add a hover effect to the info icon */
.tabs-container {
  display: flex;
  flex-wrap: wrap;

  padding-bottom: 20px;
  margin-bottom: 20px;
  gap: 20px;
  border-bottom: 0.5px solid #ccc;
}

.tab-button {
  padding: 10px 20px;
  border-radius: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  background-color: #2d3748;
  cursor: pointer;
  color: #ccc;
}

.tab-button.active {
  background-color: #e9ecef;
  border: 1px solid transparent;
  color: #1f4f8f;
}

.api-provider {
  margin-bottom: 20px;
  
}
