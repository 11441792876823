.linkedinEventContainer {
    max-width: 900px;
    margin: 2rem auto;
    background-color: #121010;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .linkedinEventHeader {
    padding: 1.5rem;
    border-bottom: 1px solid #3a3a5f;
  }
  
  .linkedinEventTitle {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
  }
  
  .eventIcon {
    color: #4a90e2;
  }
  
  
  .linkedinEventAttendee {
    border-bottom: 1px solid #3a3a5f;
    padding: 1rem;
    transition: background-color 0.2s ease;
  }
  
 
  .linkedinEventAttendee:last-child {
    border-bottom: none;
  }
  
  .attendeePreview {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .attendeeAvatar {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: linear-gradient(135deg, #0077b5 0%, #00a0dc 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    flex-shrink: 0;
    overflow: hidden;
  }
  
  .avatarImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .attendeeInfo {
    flex: 1;
  }
  
  .attendeeHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
  
  .attendeeName {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.25rem;
  }
  
  .attendeeTitle {
    color: #a3a3c2;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  .attendeeLocation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a3a3c2;
    font-size: 0.875rem;
  }
  
  .linkedinEventAttendeestoggleButton {
    color: #4a90e2;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
  
  .toggleButton:hover {
    background-color: rgba(74, 144, 226, 0.1);
  }
  
  .attendeeExpanded {
    margin-top: 1rem;
    padding-top: 1rem;
    margin-left: 4rem;
    border-top: 1px solid #3a3a5f;
  }
  
  .attendeeDetails {
    background-color: #2d2d4f;
    padding: 1rem;
    border-radius: 8px;
    color: #ffffff;
  }
  
  .attendeeConnection {
    color: #a3a3c2;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  .attendeeLinks {
    margin-top: 1rem;
  }
  
  .profileLink {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #4a90e2;
    text-decoration: none;
    font-size: 0.875rem;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  
  .profileLink:hover {
    background-color: rgba(74, 144, 226, 0.1);
  }
  
  @media (max-width: 640px) {
    .linkedinEventContainer {
      margin: 1rem 0;
      border-radius: 8px;
    }
  
    .linkedinEventHeader,
    .linkedinEventAttendee {
      padding: 1rem;
    }
  
    .attendeeExpanded {
      margin-left: 3rem;
    }
  }