.sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 40px);
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    overflow: hidden;
}
.sidebar.open {
  transform: translateX(0); /* Show sidebar */
}
.sidebar-apps-content{
  overflow: auto;
}
.sidebar-header-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: -10px !important;
  height: 40px;
  font-size: 12px ;
  margin-left: 10px;
}

.sidebar-header-wrapper h1{
  background: linear-gradient(45deg, #4f9eff, #7c4fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 24px !important;
  color: #4a90e2 ; 
}
.sidebar-menu-icon {
  margin-left: 10px;
  opacity: 0; /* Start invisible */
  transform: translateX(-30px); /* Start off-screen to the left */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
}

.sidebar.open .sidebar-menu-icon {
  margin-left: auto;
  opacity: 1; /* Make visible */
  transform: translateX(0); /* Slide to its original position */
  animation: slideLeftRight 1s ease-in-out; /* Run animation once */
}
.sidebar-wrapper.open .sidebar-menu-icon {
  margin-left: auto;
  opacity: 1; /* Make visible */
  transform: translateX(0); /* Slide to its original position */
  animation: slideLeftRight 1s ease-in-out; /* Run animation once */
}

@keyframes slideLeftRight {
  0% {
    transform: translateX(-30px); /* Start off-screen */
  }
  100% {
    transform: translateX(0); /* Slide to original position */
  }
}
.new-chat-btn {
  padding: 10px;
}
.new-chat-btn {
    background-color: #4166d5;
    border: 1px solid #4166d5;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.new-chat-btn:hover{
    background-color: #496edf;
}

.new-chat-btn svg {
    margin-right: 10px;
}

.sidebar-section {
  margin: 10px 0 10px 0;
  min-height: 0;
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #333;
  padding: 5px 10px;
  transition: all 0.3s ease;
}
.sidebar-section:hover .prospect-ai-header {
  color:  #4a90e2;
}
.favorite-chat-section:hover .favorite-chat-header {
  color:  #4a90e2;
}
.recents-section:hover .recents-header {
  color:  #4a90e2;
}
.sidebar-section h3 {
    font-size: 16px;
    color: #888;
   margin-top: 10px;
}

.sidebar-section ul {
    list-style-type: none;
    padding: 0;
}

.sidebar-section li {
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 5px;
}

.sidebar-section li:hover {
    background-color: #2c2c2c;
}

.view-all-btn {
    background: none;
    border: none;
    color: #4285f4;
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
   
    display: block;
    width: 100%;
}

.sidebar-footer {
    margin-top: auto;
    border-top: 1px solid #333;
    padding-top: 10px;
   
}

.plan-info {
    color: #4285f4;
    margin-bottom: 10px;
}

.user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}

.user-info:hover {
    background-color: #2c2c2c;
}

.user-avatar , .user-fixed-avatar{
    cursor:pointer;
    width: 30px;
    height: 30px;
    background-color: #4285f4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #ffffff;
}


.user-email {
    flex-grow: 1;
}

.dropdown-icon {
    transition: transform 0.3s ease;
}

.dropdown-icon.open {
    transform: rotate(180deg);
}

.profile-dropdown-menu {
  
    margin-top: 5px;
    overflow: auto;
}

.profile-dropdown-menu button {
    width: 100%;
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.profile-dropdown-menu button:hover {
    background-color: #2c2c2c;
}

.profile-dropdown-menu button svg {
    margin-right: 10px;
}

.sidebar-trigger {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    z-index: 100;
  }
  
  .sidebar-wrapper {
    position: fixed;
    top: 0;
    left: -320px;
    width: 300px;
    height: 100%;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .sidebar-wrapper.open {
    left: 0;
  }
  


.user-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

  .user-info-avatar{
    position: fixed;
    bottom: 30px;
    left: 20px;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    z-index: 9999;
}
.user-info-avatar.hidden {
    opacity: 0;
  }

  .chat-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
}



.chat-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-intent {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    width: 50px !important;
}
.chat-intent input {
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 2px 4px;
  outline: none;
  height: 20px;
  width: 92%;
}

.chat-intent input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.chat-timestamp {
    font-size: 0.75em;
    color: #4a90e2;
    margin-left: 10px;
}

.custom-context-section {
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #333;
    padding: 10px 10px;
    margin-bottom: 10px;
}

.custom-context-section:hover h4, .custom-context-section:hover .ai-campaign-icon 
, .custom-context-section:hover .writer-ai-icon, .custom-context-section:hover .box-icon{
    color: #4a90e2;
}
  
  .custom-context-section h4 {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 0;
    margin: 0;
    color: #888;
    font-size: 16px;
  }
  
  .accordion-icon {
    margin-left: auto ;
    transition: transform 0.3s ease;
    font-size: 14px;
    color: #888;
  }
  
  .accordion-icon.open {
    transform: rotate(90deg);
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    opacity: 0;
  }
  
  .accordion-content.open {
    max-height: 550px; /* Adjust this value based on your content */
    overflow-y: auto;
    opacity: 1;
  }
  .accordion-content.open.show-all {
    max-height: 350px; /* Adjust this value as needed */
  }
  .accordion-content-main {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
 
  }
  .accordion-content-main.open {
    max-height: 500px; /* Adjust this value based on your content */
    overflow-y: auto;
  }
  .accordion-inner {
    padding: 10px 0;
  }
  
  .content-selector {
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .content-selector label {
    margin-left: 5px;
    font-size: 14px;
  }
  
  .content-selector select {
    margin-left: 10px;
  }
 #content-dropdown{
    background-color: transparent;
    color: #ccc;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #333;
 }
  
  .radio-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 20px;
    
  }
  .radio-input {
    background-color: transparent !important;
  }
  
  .radio-options label {
    margin-bottom: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .link-input {
    display: flex;
    margin-top: 10px;
    background-color: transparent;
  }
  
  .link-input input {
    flex-grow: 1;
    margin-right: 10px;
    background-color: transparent;
    border: 1px solid #333;
   padding: 5px;
    border-radius: 5px;
    color: #ccc;
  }
  .add-btn-url{
    background-color: #4285f4;
    color: #ffffff;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #333;
    cursor: pointer;
  }
  
  .file-upload-area {
    border: 2px dashed #333;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .file-upload-label {
    display: block;
    font-weight: bold;
    color: #888;
  }

  .accordion-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: bold;
    margin-bottom: 10px;
    gap: 10px;
    position: sticky;
    
    
}
.action-point-main-header{
  margin-bottom: 0 !important;
}

.accordion-icon {
    margin-right: 10px;
    transition: transform 0.3s ease;
}

.accordion-icon.open {
    transform: rotate(90deg);
}


.no-chats-message{
    text-align: center;
    font-size: 14px;
    color: #ccc;
}


  .chat-item {
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* .chat-item:hover {
    background-color: rgba(0, 0, 0, 0.8);
  } */
  
  .chat-item.active {
    background-color: #333;
    /* font-weight: bold; */
  }
  
  .chat-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-intent {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .chat-timestamp {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    font-size: 0.60em;
    color: #666;
  }

  @media (max-width: 850px) {
    .user-info-avatar{
      display:none;
  }
  }

  .edit-chat-options {
    position: relative;
    margin-left: 5px;
}

.dropdown-menu-chat-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dropdown-menu-chat-options button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 7px 15px;
  font-size: 14px;
  color: #fff;
}

.dropdown-menu-chat-options button:hover {
    background-color: #444;
    border-radius: 4px;
}




/* Writer AI Section Styles */

.writer-ai-section {
  margin-bottom: 10px;
}

.inbox-section {
  margin-bottom: 10px;
  padding: 5 10px;
}

.writer-ai-main-header{
  padding: 0;
  margin: 0;
  color: #888 !important;
}

.writer-ai-title {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 100%;
}

.writer-ai-title h4 {
  color: #888;
}

.recents-header {
  margin: 10px 0;
}

.recents-title {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #888;
}

.writer-ai-chat-item {
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  border: 0.5px solid #333;
  margin-bottom: 5px;
}

.writer-ai-type-header {
  padding: 8px;
  transition: background-color 0.3s ease;
}
 .inbox-main-header {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  gap: 10px;
  cursor: pointer;
  width: 100%;
 }

.writer-ai-type-header:hover {
  background-color: #2c2c2c;
}

.writer-ai-type-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sidebar-section.writer-ai-section{
 margin-top: 5px;
}

.writer-ai-type-content {
  background-color: transparent;
}
.chat-type-icon,.writer-ai-icon ,.ai-campaign-icon, .box-icon{
  color: #888;
}
.ai-campaign-icon{
  margin-right: 10px;
}
.chat-type-name{
  color: #888;
  font-size: 14px;
}
.type-description {
  padding: 10px;
  color: #888;
  font-size: 11px;
  border-top: 1px solid #333;
  margin-top: 5px;
}

/* Ensure accordion animations work properly */
.content-type-header {
  padding: 8px 10px;
  font-weight: 500;
  color: #888;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content-type-header:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.content-type-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.content-type-info span{
  font-size: 14px;
}

.content-type-sessions {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.content-type-sessions.open {
  max-height: 500px !important;
  overflow: auto;
  border: 1px solid #333;
  border-radius: 4px;
}

.writer-ai-content-type {
  margin-bottom: 5px;
}

.action-point-title{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.no-sessions-message {
  padding: 5px 10px;
  color: #666;
  font-size: 12px;
  font-style: italic;
}



.session-left {
  flex-grow: 1;
  min-width: 0;
  padding-right: 10px;
}



.session-title-input {
  background: transparent;
  border: 1px solid #4285f4;
  color: #fff;
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  width: 90%;
}

.session-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.session-time {
  text-align: right;
  font-size: 11px;
  color: #888;
}

.time-date {
  margin-bottom: 2px;
}

.session-options {

  padding-left: 0px;
}

.options-trigger {
  color: #888;
  cursor: pointer;
  font-size: 18px;
  padding: 0 ;
}

.session-dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #333;
  border-radius: 4px;
  overflow: hidden;
  z-index: 101;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  min-width: 120px;
}

.session-dropdown-menu button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
}

.session-dropdown-menu button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.session-dropdown-menu .delete-button {
  color: #f93a37;
}

.button-icon {
  font-size: 12px;
}

.writer-ai-session {
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  padding: 5px;
}

.writer-ai-session:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.session-content {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 5px;
  
}

.session-left {
  flex: 1;
  padding: 0 10px 0 0 ; /* Added padding for center spacing */
  text-align: left; /* Center the title */
}

.session-title {
  font-size: 11px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 1.2; /* Add line height for better readability */
  text-align: left;
  margin-top: auto;

}

.session-right {
  display: flex;
  align-items: center; 
  gap: 8px;
  min-width: 85px; /* Set minimum width for the right section */
}

.session-time {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Right align the time */
  font-size: 11px;
  color: #888;
  line-height: 1.2; /* Match the line height */
}

.time-date {
  margin-bottom: 2px;
}

.time-hour {
  color: #666; /* Slightly darker for the time */
}

.session-options {
  position: relative; /* Keep this */
  padding-left: 2px;
  display: flex;
  align-items: flex-start;
}

.options-trigger {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 0 2px;
  line-height: 1;
}
.start-new-chat{
  padding: 0 !important;
  margin: 0 !important;
  font-size: 15px !important;
  font-weight: bold;
}
.spinner-chat{
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}