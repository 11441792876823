.linkedinJobContainer {
    max-width: 900px;
    margin: 2rem auto;
    background-color: #121010;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.linkedinJob{
    margin-bottom: 1rem;
    border-bottom: 1px solid #3a3a5f;
}
.linkedinJob:last-child {
    border-bottom: none;
}


.linkedinJobHeader {
    padding: 1rem;
    border-bottom: 1px solid #3a3a5f;
}

.linkedinJobTitle {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
    margin-left: 5px !important;
}

.jobIcon {
    color: #4a90e2;
}
.linkedinJobList{
    padding: 1rem;
    max-height: 600px;
    overflow-y: auto;
}
.linkedinJobListing {
    border-bottom: 1px solid #3a3a5f;
    padding: 1rem;
    transition: background-color 0.2s ease;
    margin-bottom: 10px !important;
}

.linkedinJobListing:last-child {
    border-bottom: none;
}

.jobPreview {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
 
}

.jobLogo {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: linear-gradient(135deg, #0077b5 0%, #00a0dc 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    flex-shrink: 0;
    overflow: hidden;
}

.jobLogo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logoImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.jobInfo {
    flex: 1;
}

.jobHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
}

.jobTitle {
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.25rem;
}

.jobCompany {
    color: #a3a3c2;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.jobLocation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #a3a3c2;
    font-size: 0.875rem;
}

.linkedinJobListingToggleButton {
    color: #4a90e2;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.toggleButton:hover {
    background-color: rgba(74, 144, 226, 0.1);
}

.jobExpanded {
    margin-top: 1rem;
    padding-top: 1rem;
    margin-left: 3.4rem;
    border-top: 1px solid #3a3a5f;
}

.jobDetails {
    background-color: #2d2d4f;
    padding: 1rem;
    border-radius: 8px;
    color: #ffffff;
}

.jobSalary {
    color: #a3a3c2;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.jobLinks {
    margin-top: 1rem;
}

.jobApplyLink {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #4a90e2;
    text-decoration: none;
    font-size: 0.875rem;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.jobApplyLink:hover {
    background-color: rgba(74, 144, 226, 0.1);
}

@media (max-width: 640px) {
    .linkedinJobContainer {
        margin: 1rem 0;
        border-radius: 8px;
    }

    .linkedinJobHeader,
    .linkedinJobListing {
        padding: 1rem;
    }

    .jobExpanded {
        margin-left: 3rem;
    }
}
