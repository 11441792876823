/* linkedin-profile.css */

.profile-container {
    background-color: #1a1a2e;
    color: #e2e2e2;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    max-width: 1200px;
    margin: 2rem auto;
  }
  
  .profile-header {
    margin-bottom: 2rem;
    border-bottom: 1px solid #2d2d44;
    padding: 1rem;
    background-color: #252542;
  }
  
  .company-name {
    font-size: 2.5rem;
    font-weight: 700;
    background: linear-gradient(45deg, #4a90e2, #67b1f7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.5rem;
  }
  
  .tagline {
    color: #b3b3b3;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .info-card {
    background-color: #252542;
    border-radius: 8px;
    padding: 1.5rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 1rem;
  }
  
  .info-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .affiliated-companies-card {
    background-color: #252542;
    border-radius: 8px;
    padding: 1.5rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 2rem;
  }

  .crunchbase-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #4a90e2;
    text-decoration: none;
  }

  .crunchbase-link:hover {
    color: #67b1f7;
    text-decoration: underline;
  }

  .global-locations-card {
    background-color: #252542;
    border-radius: 8px;
    padding: 1.5rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 2rem;
  }

  .specialties-card {
    background-color: #252542;
    border-radius: 8px;
    padding: 1.5rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 2rem;
  }
  
  .company-profile-card-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .card-icon {
    color: #4a90e2;
    width: 24px;
    height: 24px;
  }
  
  .card-title {
    font-weight: 600;
    color: #ffffff;
    font-size: 1.1rem;
  }
  
  .description-section {
    background-color: #252542;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    line-height: 1.8;
  }
  
  .specialties-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  
  .specialty-badge {
    background-color: #3a3a5f;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    transition: background-color 0.2s ease;
  }
  
  .specialty-badge:hover {
    background-color: #4a4a77;
  }
  
  .stats-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #2d2d44;
  }
  
  .stat-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #b3b3b3;
  }
  
  .website-link {
    color: #4a90e2;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .website-link:hover {
    color: #67b1f7;
    text-decoration: underline;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .profile-container {
      padding: 1rem;
      margin: 1rem;
    }
  
    .company-name {
      font-size: 2rem;
    }
  
    .info-grid {
      grid-template-columns: 1fr;
    }
  
    .stats-footer {
      grid-template-columns: 1fr;
    }
  }
  
  /* Animation for cards */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .info-card {
    animation: fadeIn 0.3s ease-out forwards;
  }
  
  /* Custom scrollbar */
  .description-section {
    scrollbar-width: thin;
    scrollbar-color: #4a90e2 #252542;
  }
  
  .description-section::-webkit-scrollbar {
    width: 8px;
  }
  
  .description-section::-webkit-scrollbar-track {
    background: #252542;
  }
  
  .description-section::-webkit-scrollbar-thumb {
    background-color: #4a90e2;
    border-radius: 4px;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .industry-tag {
    background-color: #3a3a5f;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    
  }
  
  .affiliated-companies {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .affiliate-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #fff;
    text-decoration: none;
    padding: 4px 12px;
    background-color: #3a3a5f;
    border-radius: 16px;
    font-size: 0.9rem;
  }
  
  .affiliate-item:hover {
    background-color: #e4e6eb;
    color: #0a66c2;
    text-decoration: underline;
  }
  
  .locations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
    margin-top: 12px;
  }
  
  .location-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
  }
  
  .location-icon {
    color: #666;
  }
  
  .more-locations {
    color: #666;
    font-style: italic;
  }
  .more-locations:hover{
    color: #666;
    font-style: italic;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .subtitle {
    font-size: 0.9rem;
    color: #666;
    margin-top: 4px;
  }
  
  .linkedin-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #0a66c2;
    text-decoration: none;
  }
  
  .linkedin-link:hover {
    text-decoration: underline;
  }

  .company-header-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .company-logo {
    width: 54px;
    height: 54px;
    object-fit: contain;
    border-radius: 50%;
  }
  
  .company-header-text {
    display: flex;
    flex-direction: column;
  }

  .company-profile-header {
    background: #252542;
    padding: 2rem;
    border-radius: 12px;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }

  .company-header-content {
    display: flex;
    align-items: center;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  .logo-container {
    flex-shrink: 0;
  }

  .company-logo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: contain;
    background: white;
    padding: 0.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  .company-header-text {
    flex-grow: 1;
  }

  .company-name {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2d3748;
    margin: 0 0 0.5rem 0;
  }

  .tagline {
    font-size: 1.1rem;
    color: #4a5568;
    margin: 0 0 1rem 0;
  }

  .quick-stats {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .stat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #718096;
    font-size: 0.95rem;
  }

  .stat.website {
    text-decoration: none;
    color: #4299e1;
    transition: color 0.2s;
  }

  .stat.website:hover {
    color: #2b6cb0;
  }

  .stat-icon {
    color: currentColor;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .company-header-content {
      flex-direction: column;
      text-align: center;
      gap: 1rem;
    }

    .quick-stats {
      justify-content: center;
    }

    .company-name {
      font-size: 2rem;
    }

    .company-logo {
      width: 100px;
      height: 100px;
    }
  }

  .show-more-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: none;
    border: none;
    color: #0066cc;
    cursor: pointer;
    padding: 8px;
    margin: 8px auto;
    font-size: 14px;
  }

  .show-more-btn:hover {
    text-decoration: underline;
  }
  .company-profile-show-less-button{
    margin-bottom: 1rem;
  }
  .company-profile-buttons-section{
    margin-top: 1rem;
  }
  .company-profile-research-h4{
    margin-top: 1rem;
    color: gray !important;
  }
 