.web-content-container {
    width: calc(100% - 20px);
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .web-content-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #1f2937;
  }
  
  .search-results-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .search-result-item {
    border: 1px solid #2c2c2c;
    border-radius: 0.5rem;
    padding: 1rem;
    transition: background-color 0.2s ease;
    background-color:  #121010;
  }
  
 
  
  .result-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #2563eb;
    margin-bottom: 0.5rem;
    line-height: 1.4;
  }
  
  .result-link {
    display: block;
    color: #059669;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    word-break: break-all;
  }
  
  .result-link:hover {
    text-decoration: underline;
  }
  
  .result-description {
    color: #4b5563;
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }
  
  .result-date {
    color: #6b7280;
    font-size: 0.875rem;
  }
  
  /* Animation for hover effect */
  .search-result-item {
    transform: translateY(0);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  

  
  /* Responsive styles */
  @media (max-width: 768px) {
    .web-content-container {
      padding: 0.5rem;
    }
  
    .result-title {
      font-size: 1rem;
    }
  
    .search-result-item {
      padding: 0.75rem;
    }
  }