:root {
    --primary-color: #4a90e2;
    --primary-dark: #42a5f5;
    --accent-color: #ffd700;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --bg-color: #1a202c;
    --card-bg: #2d3748;
    --text-color: #e0e0e0;
    --text-secondary: #b0b0b0;
    --border-color: #404040;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --message-user-bg: #000;
    --message-ai-bg: #2d3748;
    --input-bg: #4a5568;
    --radius: 8px;
    --transition: all 0.3s ease;
    --color:white;
}

.dark {
    --primary-color: #4a90e2;
    --primary-dark: #42a5f5;
    --bg-color: #1a1a1a;
    --card-bg: #2d2d2d;
    --text-color: #e0e0e0;
    --text-secondary: #b0b0b0;
    --border-color: #404040;
    --shadow-color: rgba(0, 0, 0, 0.3);
    --message-user-bg: #4a90e2;
    --message-ai-bg: #3d3d3d;
    --input-bg: #3d3d3d;
    --color:white;
}




.writer-ai {
    padding-top: 80px; /* Height of navbar */
    /* height: 80vh !important; */
    background-color: var(--bg-color);
    transition: all 0.3s ease;
   
}
/* Welcome Screen */
.welcome-screen {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}
.writer-ai-title{
    text-align: center;
    color: var(--text-color);
    margin-bottom: 0;
}

/* Welcome screen heading gradient styles */
.welcome-screen h2 {
    text-align: center;
    color: transparent;
    background: linear-gradient(135deg, #4a90e2 0%, #357abd 50%, #64b5f6 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2rem;
    font-size: 1.7rem;
    font-weight: 700;
    padding: 0.5rem 0;
    line-height: 1.3;
    position: relative;
    animation: gradientFlow 8s ease infinite;
    background-size: 200% 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}
.static-text {
    color: var(--text-color);
     
}

.typing-text {
    position: relative;
    color: var(--primary-color);
    background: linear-gradient(135deg, #4a90e2 0%, #64b5f6 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-right: 3px solid var(--primary-color);
    margin: 0 2px;
    white-space: nowrap;
    overflow: hidden;
   
    animation: typing-erasing 4s steps(9) ,
    blink 0.8s step-end infinite;
}

@keyframes typing-erasing {
    0% { 
        width: 0;
        opacity: 0;
    }
    5% {
        width: 0;
        opacity: 1;
    }
    20% {
        width: 115px;
        opacity: 1;
    }
    35% {
        width: 215px;
        opacity: 1;
    }
    45% {
        width: 315px;
        opacity: 1;
    }
    75% {
        width: 515px;
        opacity: 1;
    }
    100% { 
        width: 615px;
        opacity: 1;
      
    }
}

@keyframes blink {
    50% { 
        border-color: transparent;
    }

}


/* Add a subtle animation to the gradient */
@keyframes gradientFlow {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Dark mode variant */
.dark .welcome-screen h2 {
    background: linear-gradient(135deg, #64b5f6 0%, #42a5f5 50%, #90caf9 100%);
    background-clip: text;
    -webkit-background-clip: text;
 
}


/* Recent Projects */
.recent-projects {
    margin-bottom: 3rem;
}
.generate-btn{
    background-color: var(--primary-dark);
    color: var(--color);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
    margin: auto;
}
.generate-btn:hover{
    background-color: var(--primary-color);
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}

.project-card {
    background: var(--card-bg);
    border-radius: var(--radius);
    padding: 1.5rem;
    box-shadow: 0 2px 4px var(--shadow-color);
    transition: var(--transition);
}

.project-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px var(--shadow-color);
}

/* Content Types Grid */
.content-types-grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
    overflow-x: hidden;
    padding: 1rem;
    transition: transform 0.5s ease; /* Add smooth transition */
}

.content-types-grid::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.content-types-wrapper {
    position: relative;
    overflow: hidden;
}

.content-type-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background: var(--card-bg);
    /* border: 2px solid var(--border-color); */
    border-radius: var(--radius);
    cursor: pointer;
    /* transition: var(--transition); */
    color: var(--color);
    width: 280px;
    border: 2px solid transparent;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    
}

.content-type-btn::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    transition: all 0.4s ease;
    opacity: 0;
    box-shadow: 0 0 0 2px var(--primary-color);
}

.content-type-btn:hover {
    transform: scale(1.03) translateY(-4px);
    box-shadow: 0 8px 20px rgba(74, 144, 226, 0.2),
    0 4px 12px rgba(74, 144, 226, 0.1);
}

.content-type-btn:hover .content-type-icon {
    transform: scale(1.1);
    color: var(--primary-color);
}

.content-type-btn:hover::after {
    opacity: 1;
    inset: -4px;
}

.content-type-btn:hover .content-type-icon {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.content-type-icon {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.content-type-name {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.content-type-description {
    font-size: 0.9rem;
    color: var(--text-secondary);
    text-align: center;
}

/* Input Screen */
.input-screen {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background: var(--card-bg);
    border-radius: var(--radius);
    box-shadow: 0 2px 4px var(--shadow-color);
}

.input-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-top: 0;
    color: var(--color);
}

.input-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.input-group label {
    font-weight: 500;
    text-align: left;
}

.input-group input,
.input-group textarea,
.input-group select {
    padding: 0.75rem;
    border: 2px solid var(--border-color);
    border-radius: var(--radius);
    background: var(--input-bg);
    color: var(--text-color);
    font-size: 1rem;
    transition: var(--transition);
}

.input-group textarea {
    min-height: 80px;
    resize: vertical;
}

.input-group input:focus,
.input-group textarea:focus,
.input-group select:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* Keywords */
.keywords-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.keyword-chip {
    background: var(--primary-color);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.keyword-chip button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0 0.25rem;
}

/* Progress Bar */
.progress-container {
    width: 100%;
    height: 4px;
    background: var(--border-color);
    border-radius: var(--radius);
    margin: 1rem 0;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background: var(--primary-color);
    transition: width 0.3s ease;
}

/* Workspace */
.workspace {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-width: 1400px;
    margin:  auto;
    padding:  2rem;
    overflow: hidden;
    height:100%
}
.chat-panel {
    position: relative;
}

.chat-panel,
.content-panel {
    background:  #161b22 !important;
    border-radius: var(--radius);
    box-shadow: 0 2px 4px var(--shadow-color);
    display: flex;
    flex-direction: column;
    height: 80vh;
    border: 1px solid var(--border-color);
}

.chat-header,
.content-header {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color);
}
.content-header{
    padding: 0 1rem;
}
.chat-messages {
    flex: 1;
    position: relative;
    scroll-behavior: smooth;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.message {
    padding: 0.75rem 1rem;
    border-radius: var(--radius);
    max-width: 100%;
    position: relative;
}
.message-text {
    word-wrap: break-word;
}
.message-timestamp {
    font-size: 0.75em;
    color: #666;
    text-align: right;
    margin-top: 5px;
}


.writer-user-message {
    background: var(--message-user-bg);
    color: white;
    align-self: flex-end;
    color: var(--text-color);
}

.writer-ai-message {
    background: var(--message-ai-bg);
    align-self: flex-start;
    color: var(--text-color);
}

.chat-input-form {
    padding: 1rem;
    border-top: 1px solid var(--border-color);
    display: flex;
    gap: 0.5rem;
}

.chat-input-form input {
    flex: 1;
    padding: 0.75rem;
    border: 2px solid var(--border-color);
    border-radius: var(--radius);
    background: var(--input-bg);
    color: var(--text-color);
}

.chat-input-form button {
    padding: 0.75rem 1.5rem;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
}

.chat-input-form button:hover {
    background: var(--primary-dark);
}

/* Generated Content */
.generated-content {
    flex: 1;
    padding: 1.5rem;
    overflow-y: auto;
}

.content-text {
    white-space: pre-wrap;
    line-height: 1.6;
    color: var(--text-color);
}

.edit-textarea {
    width: 100%;
    height: 100%;
    padding: 1rem;
    border: 2px solid var(--border-color);
    border-radius: var(--radius);
    background: var(--input-bg);
    color: var(--text-color);
    font-size: 1rem;
    resize: none;
}

/* Action Buttons */
.content-actions {
    display: flex;
    gap: 0.5rem;
}

.action-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
    background: var(--primary-color);
    color: white;
}

.action-btn:hover {
    background: var(--primary-dark);
}

.cancel-btn {
    background: var(--danger-color);
}

/* Loading Animation */
.loading-dots {
    display: flex;
    gap: 4px;
    align-items: center;
    margin: auto;
}

.loading-dots span {
    width: 4px;
    height: 4px;
    background: currentColor;
    border-radius: 50%;
    animation: bounce 0.5s infinite alternate;
}

.loading-dots span:nth-child(2) { animation-delay: 0.1s; }
.loading-dots span:nth-child(3) { animation-delay: 0.2s; }

@keyframes bounce {
    to { transform: translateY(-4px); }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .workspace {
        grid-template-columns: 1fr;
        height: auto;
        gap: 1rem;
    }

    .chat-panel,
    .content-panel {
        height: 500px;
    }
}

@media (max-width: 768px) {
    .writer-ai {
        padding: 1rem;
    }

    .content-types-grid {
        grid-template-columns: 1fr;
    }

    .input-screen {
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .content-actions {
        flex-direction: column;
    }

    .action-btn {
        width: 100%;
    }
}

/* Icon Buttons and Dropdowns */
.action-buttons {
    display: flex;
    gap: 0.5rem;
    position: relative;
    margin: auto;
}

.icon-btn {
    background: none;
    border: none;
    font-size: 1.2rem;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: var(--radius);
    transition: var(--transition);
    position: relative;
    color: var(--color);
}

.icon-btn:hover {
    background: var(--bg-color);
}

.dropdown-container {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--card-bg);
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    box-shadow: 0 2px 10px var(--shadow-color);
    z-index: 1000;
    min-width: 200px;
    margin-top: 0.5rem;
}

.dropdown-menu button {
    width: 100%;
    text-align: left;
    padding: 0.75rem 1rem;
    border: none;
    background: none;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: var(--transition);
}

.dropdown-menu button:hover {
    background: var(--bg-color);
}

/* Success message for copy */
.copy-success {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--card-bg);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    font-size: 0.8rem;
    box-shadow: 0 2px 5px var(--shadow-color);
}

/* For mobile devices */
@media (max-width: 768px) {
    .dropdown-menu {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        border-radius: var(--radius) var(--radius) 0 0;
        padding-bottom: env(safe-area-inset-bottom);
    }
    
    .dropdown-menu button {
        padding: 1rem;
    }
}

/* Add to your existing CSS file */

/* Custom Content Section Styles */
.custom-content-section {
    margin: 2rem 3rem;
    padding: 2rem ;
    background: var(--card-bg);
    border-radius: var(--radius);
    box-shadow: 0 2px 4px var(--shadow-color);
}

.custom-content-section .input-group {
    margin-bottom: 0;
}

.custom-content-section .chat-input-form {
    margin-top: 1rem;
    padding: 1.5rem 0;
   
}

.custom-content-section .chat-input-form button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
    font-size: 1.2rem;
   
}

.custom-content-section .chat-input-form button:hover {
    background: var(--primary-dark);
}

.custom-content-section .chat-input-form input {
    flex: 1;
    padding: 0.75rem;
    border: 2px solid var(--border-color);
    border-radius: var(--radius);
    background: var(--input-bg);
    color: var(--text-color);
}

.custom-content-section .chat-input-form input:focus {
    border-color: var(--primary-color);
    outline: none;
}



/* Enhanced chat message styles */
.writer-ai-message {
    background: var(--message-ai-bg);
    color: var(--text-color);
    padding: 1rem;
    border-radius: var(--radius);
    max-width: 80%;
    align-self: flex-start;
    box-shadow: 0 1px 3px var(--shadow-color);
}

.writer-user-message {
    background: var(--message-user-bg);
    color: white;
    padding: 1rem;
    border-radius: var(--radius);
    max-width: 80%;
    align-self: flex-end;
    box-shadow: 0 1px 3px var(--shadow-color);
}

.content-types-container {
    position: relative;
    margin: 2rem 0;
    padding: 0 30px 0 30px; /* Space for arrows */
}

.content-types-grid-container {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%; /* Space for arrows */
}
.content-types-page {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
    min-width: calc(100% - 2rem);
    padding: 1rem;
    
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--card-bg);
    border: 1px solid var(--border-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer ;
    z-index: 100;
    box-shadow: 0 2px 4px var(--shadow-color);
}

.scroll-button:hover {
    background: var(--bg-color);
}

.scroll-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.scroll-left {
    left: -10px;
}

.scroll-right {
    right: -10px;
}


/* Responsive grid layouts */
@media (min-width: 1400px) {
    .content-types-grid {
        grid-template-columns: repeat(4, 1fr); /* 4 buttons per row on large screens */
    }
    .content-type-btn {
        width: 100%;
    }
    .content-types-page {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1399px) and (min-width: 1000px) {
    .content-types-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 buttons per row on medium screens */
    }
    .content-type-btn {
        width: 100%;
    }
    .content-types-page {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 999px) {
    .content-types-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 buttons per row on small screens */
    }
    .content-type-btn {
        width: 100%;
    }
    .content-types-page {
        grid-template-columns: repeat(2, 1fr);
    }
}




.clickable-message {
    cursor: pointer;
    padding: 10px;
    background-color: #f0f7ff;
    border-radius: 8px;
    transition: background-color 0.2s;
}

.clickable-message:hover {
    background-color: #e1efff;
}

.clickable-message::after {
    content: '🔍';
    margin-left: 8px;
    font-size: 14px;
}


.scroll-to-bottom-btn {
    position: absolute;
    bottom: 80px; /* Adjust based on your input height */
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--bg-color);
    border: 1px solid var(--border-color);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    z-index: 10;
}

.scroll-to-bottom-btn:hover {
    background: var(--card-bg);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.scroll-to-bottom-btn:active {
    transform: translateY(0);
}