.message-list-container {
  position: relative;
  height: 80vh;
  overflow: auto;
  /* background-color: #aa3838; */
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.welcome-message-toggle {
  position: sticky;
  top: 0;
  right: 0;
  padding: 10px;
  text-align: right;
  z-index: 10;
  background-color: transparent;
  cursor: pointer;
}

.welcome-message-toggle button {
  background-color: #444;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer !important;
  transition: background-color 0.3s;
}

.welcome-message-toggle button:hover {
  background-color: #333;
  cursor: pointer;
}



.try-button {
  margin-left: 10px;
  cursor: pointer !important;
}

.user-text {
  background-color: #000;
  border-radius: 10px;
  padding: 0 15px;
  margin-left: 0px;
  padding-bottom: 12px;
  width: 100% !important;
  word-wrap: break-word;
  word-break: break-word;

}


.ai-text {
  background-color: #2d3748;
  border-radius: 10px;
  padding: 0 15px;
  padding-bottom: 12px;
  width: 100% !important;
  word-wrap: break-word;
  word-break: break-word;
}


.message-timestamp {
  font-size: 11px;
  color: #666;
  text-align: right;
}

.user-message .message-timestamp {
  color: rgba(255, 255, 255, 0.7);
}

.ai-message .message-timestamp {
  color: rgba(255, 255, 255, 0.7);
}

.action-icons-container {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-left: 12px;
}

.action-icon {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.action-icon:hover {
  color: #3893DD;
  transform: scale(1.1);
}

.research-results-container {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.research-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.research-header h3 {
  margin: 0;
  color: #1e293b;
  font-size: 1.1rem;
}

.research-timestamp {
  color: #64748b;
  font-size: 0.9rem;
}

.research-section {
  margin-bottom: 16px;
}

.research-section h4 {
  color: #334155;
  margin: 0 0 8px 0;
  font-size: 1rem;
}

.research-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.research-item {
  display: flex;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

.research-label {
  font-weight: 600;
  color: #475569;
  min-width: 120px;
  margin-right: 12px;
}

.research-value {
  color: #334155;
  word-break: break-word;
}

.research-value pre {
  margin: 0;
  white-space: pre-wrap;
}

.message-feedback-icons {
  position: absolute;
  bottom: -10px;
  left: 20px;
  display: flex;
  gap: 12px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: #21201c;
  border-radius: 10px;
  padding: 8px;
  border: 1px solid #2d3748;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #ccc;
  font-size: 14px;
}

.ai-message-container {
  position: relative;
}

.ai-message-container:hover .message-feedback-icons {
  opacity: 1;
}

.feedback-icon {
  cursor: pointer;
  transition: color 0.2s ease;
}

.feedback-icon:hover {
  color: #4a90e2;
}

.feedback-icon.active {
  color: #4a90e2;
}



/* Optional: Add a copied state style */
.feedback-icon.copied {
  color: #4caf50;
}

.feedback-icon-tooltip-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.feedback-icon-tooltip {
  position: absolute;
  background: #000;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}
.copied-message-tooltip{
  background-color: #000;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  bottom: 100%;
  left: 120%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}
.copied-message-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 43% !important;
  margin-left: -5px;
}

.feedback-icon-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.feedback-icon-tooltip-wrapper:hover .feedback-icon-tooltip {
  opacity: 1;
  visibility: visible;
}

/* Examples Navigation Styles */
.ai-examples-wrapper {
  background: #252545;
  border-radius: 20px;
  padding: 2rem;
  color: #ffffff;
  margin: 0 0 2rem 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.ai-examples-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.ai-examples-header h2 {
  font-size: 2rem;
  font-weight: 600;
  background: linear-gradient(45deg, #4f9eff, #7c4fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 1;
}

.ai-examples-header p {
  color: #a8b8ff;
  font-size: 1.1rem;
  margin: 0;
  opacity: 0.9;
}

.ai-examples-header::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(45deg, #4f9eff, #7c4fff);
  border-radius: 2px;
  opacity: 0.5;
}

.examples-navigation {
  width: 100%;
  margin-top: 20px;
}

.examples-navigation-container {
  width: 100%;
}

.examples-navigation-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;

  padding-bottom: 10px;
}

.examples-nav-tab {
  padding: 10px 20px;
  border: none;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #a8b8ff;
  transition: all 0.3s ease;
  border-radius: 100px;
}

.examples-nav-tab:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.examples-nav-tab.active {
  color: #fff;
  background: linear-gradient(45deg, #4f9eff, #7c4fff);
 
  box-shadow: 0 2px 8px rgba(79, 158, 255, 0.3);
}

.examples-content {
  display: none;
  padding: 20px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
}

.examples-content.active {
  display: block;
  animation: fadeIn 0.3s ease;
}

.examples-content h3 {
  color: #4f9eff;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid rgba(79, 158, 255, 0.2);
  padding-bottom: 0.5rem;
}

.examples-list {
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0;
}

.examples-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.examples-list li:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-1px);
}

.examples-list span {
  flex: 1;
  margin-right: 15px;
  font-size: 14px;
  color: #e8ecff;
}

.examples-run-button {
  background: linear-gradient(45deg, #4f9eff, #7c4fff);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.examples-run-button:hover {
  transform: translateX(2px);
  box-shadow: 0 2px 8px rgba(79, 158, 255, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Remove old styles */
.view-toggle-buttons,
.examples-grid,
.example-section,
.example-prompt-list,
.try-button,
.tab-content,
.tab-button {
  display: none;
}

/* Grid View */
[data-view-mode="grid"] .examples-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

/* List View */
[data-view-mode="list"] .examples-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

[data-view-mode="list"] .example-section {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  width: calc(100% - 3rem);
}

[data-view-mode="list"] .example-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

[data-view-mode="list"] .example-section h3 {
  color: #4f9eff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  border-bottom: 2px solid rgba(79, 158, 255, 0.2);
  padding-bottom: 0.5rem;
}

[data-view-mode="list"] .example-prompt-list {
  width: 100%;
}

[data-view-mode="list"] .example-prompt-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  transition: background 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  [data-view-mode="grid"] .examples-grid {
    grid-template-columns: 1fr;
  }

  [data-view-mode="list"] .examples-grid {
    padding: 0.5rem;
  }

  [data-view-mode="list"] .example-section {
    padding: 1rem;
  }
}

[data-view-mode="grid"] .example-section {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  height: auto;
}

[data-view-mode="grid"] .example-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

[data-view-mode="grid"] .example-section h3 {
  color: #4f9eff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  border-bottom: 2px solid rgba(79, 158, 255, 0.2);
  padding-bottom: 0.5rem;
}

.view-toggle-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.view-toggle-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 10px 20px;
  border: none;
  background: linear-gradient(45deg, #4f9eff, #7c4fff);
  color: white;
  border-radius: 100px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.view-toggle-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(79, 158, 255, 0.3);
}

.view-toggle-icon {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .view-toggle-buttons {
    padding: 0.5rem;
  }
  
  .view-toggle-btn {
    min-width: 80px;
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
}

