.contact-list-container {
  overflow: auto !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-list-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #ccc;
  /* border-radius: 10px !important; */
  table-layout: fixed;
  max-height: 500px;
  overflow: auto;
}


.contact-list-table th,
.contact-list-table td {
  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ccc;
}

.contact-list-table th {
  background-color: #252542;
  font-weight: bold;
  cursor: pointer;
}

.contact-list-table th:hover {
  background-color: #313155;
}

.contact-list-table tr {
  background-color: #1a1a2e;
}

/* Column width specifications */
.contact-list-table th:nth-child(1),
.contact-list-table td:nth-child(1) {
  text-align: left !important;
  width: 35% !important;  /* List Name column */
}

.contact-list-table th:nth-child(2),
.contact-list-table td:nth-child(2) {
  width: 20%;  /* List ID column */
}

.contact-list-table th:nth-child(3),
.contact-list-table td:nth-child(3) {
  width: 30%;  /* Created Date column */
}

.contact-list-table th:nth-child(4),
.contact-list-table td:nth-child(4) {
  width: 15%;  /* Description column */
}

.contact-list-header {
  margin-bottom: 20px;
  color: #ccc;
}

.contact-list-header h3 {
  font-size: 18px;
  font-weight: 600;
}

.view-list-button {
  background-color: #4a90e2;
  color: #ccc;
  border: none;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-list-button:hover {
  background-color: #357abd;
}

.expanded-row {
  background-color: #1a1a2e;

}

.expanded-row > td {
  padding: 20px !important;
  overflow: visible !important;
 
}




.contact-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.contact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-info {
  flex: 1;
}

.contact-info h4 {
  margin: 0 0 5px 0;
  color: #ccc;
}

.contact-info p {
  margin: 0 0 3px 0;
  color: #999;
  font-size: 0.9em;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #ccc;
}

.no-contacts {
  text-align: center;
  padding: 20px;
  color: #999;
}

.contacts-section {
  width: calc(100% - 20px);
  overflow: hidden;
  padding: 0 10px;
  overflow: visible !important;
 
}


.contacts-grid-container {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  gap: 10px;
  padding: 0 10px;
  overflow: visible !important;
  overflow-y: auto !important;
}


.grid-nav-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10;
}

.grid-nav-button:hover {
  background: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.grid-nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.grid-nav-button.left {
  left: 0;
}

.grid-nav-button.right {
  right: 0;
  
}
.contact-cards {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px ;
  width: calc(100% - 20px) !important;
  margin: 0 auto;
  padding: 0 10px;
  overflow: visible !important;

}

.contact-card {
  position: relative;
  width: 130px;
  height: 120px;
  perspective: 1000px;
  cursor: pointer;
  margin: 10px;
  background-color: #1a1a2e;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
  transform-style: preserve-3d;
}

.contact-card.flipped .card-inner {
  transform: rotateY(180deg);
}

.contact-card.flipped {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: visible !important;
}

.contact-card.flipped .contacts-section{
  overflow: visible !important;
}

.card-front{
  position: absolute;
  width: 110px;
  height: auto;
  backface-visibility: hidden;
  background: #252542;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  overflow: hidden;
}
.card-back {
  position: absolute;
  width: calc(100% - 10px);
  height: 100%;
  backface-visibility: hidden;
  background: #252542;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 999999;
  overflow: visible !important;
  height: auto !important;
}

.card-back {
  transform: rotateY(180deg);
  overflow-y: auto;
  overflow: visible !important;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.contact-preview {
  margin-top: 10px;
  color: #666;
}

.contact-basic-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.contact-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4a90e2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.contact-card h4 {
  margin: 0;
  font-size: 16px;
  color: #ccc;
}

.contact-details {
  display: none;
  padding-top: 15px;
}



 .contact-details {
  display: block;
}

.contact-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #999;
}

.contact-search {
  width: calc(100% - 20px);
  padding: 8px 12px;
  margin-bottom: 15px;
  border: 1px solid #444;
  border-radius: 4px;
  background: #252542;
  color: #ccc;
}

.contacts-footer {
  margin-top: 15px;
  text-align: right;
  color: #ccc;
}
.contact-action-icons-container {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  
}

.contact-action-icon {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.contact-action-icon:hover {
  color: #3893DD; 
  transform: scale(1.1);
}
.contact-name-container{
  display: flex;
  align-items: center;
  margin-bottom: 10px;

}

.card-back-icons{
  display: flex;
  gap: 20px;
  margin-left: 20px;
}

@media (max-width: 1024px) {
  .contact-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .contact-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.contacts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.linkedin-style-card {
    border: 1px solid #1a1a2e;
    border-radius: 8px;
    overflow: hidden;
    background: #252542;
    transition: box-shadow 0.3s ease;
}

.linkedin-style-card:hover {
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.card-header {
  background: linear-gradient(135deg, #0077b5, #00a0dc);
    height: 60px;
    position: relative;
}

.contact-avatar {
    width: 72px;
    height: 72px;
    background: #0a66c2;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    position: absolute;
    bottom: -36px;
    left: 20px;
    border: 2px solid white;
}

.card-content {
    padding: 45px 16px 16px;
}

.card-content h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.card-content .title,
.card-content .company {
    margin: 4px 0;
    font-size: 14px;
    color: #888;
    white-space: normal;
    word-wrap: break-word;
    overflow: visible;
}
.card-content .company{
  color: #ccc;
}

.contact-view-action-buttons {
    margin-top: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    gap: 8px;
}

.connect-button {
    width: 100%;
    padding: 6px 16px;
    background: #0a66c2;
    color: white;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-weight: 600;
}

.connect-button:hover {
    background: #084482;
}

.icon-buttons {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 8px;
}

.action-icon {
    cursor: pointer;
    color: #ccc;
}

.action-icon:hover {
    color: #0a66c2;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
}

.contact-list-pagination-button {
    background: none;
    border: 1px solid #0a66c2;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ccc;
}

.contact-list-pagination-button:disabled {
    border-color: transparent;
    background-color: #1a1a1a;
    color: #ccc;
    cursor: not-allowed;
    opacity: 0.5;
}

.contact-list-disabled-icon {
    opacity: 0.5;
    cursor: not-allowed;
}

.total-contacts{
  color: #ccc;
  font-size: 14px;
  text-align: right;
}

.spinner {
    width: 11px;
    height: 11px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.menu-container {
    position: relative;
    display: inline-block;
}

.contact-view-dropdown-menu {
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: 5px;
    background: #252542;
    border: 1px solid #444;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
    min-width: 150px;
}

.contact-view-dropdown-menu div {
    color: #ccc;
    padding: 8px 12px;
    cursor: pointer;
}

.contact-view-dropdown-menu div:hover {
    background-color: #313155;
}
