.video-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
    margin: 20px 0;
  }
  
  .video-note {
    font-size: 16px;
    font-weight: bold;
    color: #ebebeb;
    margin-bottom: 10px;
    text-align: left;  
    width: 100%;  
  }
  
  
  .video-wrapper {
    width: 100%;
    max-width: 600px;
    background: #000;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .video-player {
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
  }