@keyframes gradient-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes glowing-pulse {
  0% {
    box-shadow: 
      0 0 10px rgba(66, 153, 225, 0.5), 
      0 0 20px rgba(66, 153, 225, 0.3), 
      0 0 30px rgba(66, 153, 225, 0.2);
  }
  50% {
    box-shadow: 
      0 0 30px rgba(66, 153, 225, 0.8), 
      0 0 40px rgba(66, 153, 225, 0.6), 
      0 0 50px rgba(66, 153, 225, 0.4);
  }
  100% {
    box-shadow: 
      0 0 10px rgba(66, 153, 225, 0.5), 
      0 0 20px rgba(66, 153, 225, 0.3), 
      0 0 30px rgba(66, 153, 225, 0.2);
  }
}

@keyframes icon-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.mic-button.listening {
  animation: gradient-flow 4s infinite, glowing-pulse 2s infinite;
  background: linear-gradient(45deg, #00ffea, #007bff, #00ffea);
  background-size: 200% 200%;
  color: white;
  border-radius: 50%;
  border: none;
  padding: 0.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
  z-index: 1001; /* Above the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mic-button.listening svg {
  animation: icon-pulse 1.5s infinite; /* Pulsating effect for the icon */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
  z-index: 1000; /* Below the microphone button */
 
}

.overlay p {
  color: white;
  font-size: 2rem;
  margin-top: 20px; /* Space between the button and text */
  text-align: center;
}

.mic-button {
  margin-right: 30px;
  padding: 0.5rem;
  transition: all 0.4s ease ;
  margin-top: 10px ;
  
}
.mic-button:hover, .chat-button:hover {
  color: #4a90e2;
}