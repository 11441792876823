.onepgr-save-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .onepgr-save-modal {
    background-color: #333;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    color: #ccc;
  }
  
  .onepgr-save-modal h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: #ccc;
  }
  
  .onepgr-input-group {
    margin-bottom: 1.5rem;
  }
  
  .onepgr-input-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #ccc;
  }
  
  .onepgr-input-group input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #666;
    border-radius: 4px;
  }
  
  .onepgr-button-group {
    display: flex;
    justify-content: flex-end;
  }
  
  .onepgr-button-group button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .onepgr-cancel-btn {
    background-color: #e74c3c;
    color: #ccc;
    margin-right: 0.5rem;
    border: 1px solid transparent;
  }
  
  .onepgr-cancel-btn:hover {
    background-color:  #c0392b;
    color: #fff;
  }
  
  .onepgr-save-btn {
    background-color: #007bff;
    color: #ccc;
    border: 1px solid transparent;
  }
  
  .onepgr-save-btn:hover {
    background-color: #0056b3;
  
    color: #fff;
  }
  
  @media (max-width: 480px) {
    .onepgr-save-modal {
      width: 95%;
      padding: 1.5rem;
    }
  }

  .onepgr-radio-group {
    margin-bottom: 15px;
  }
  
  .onepgr-radio-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .onepgr-input-group select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }