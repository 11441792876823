.make-call-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10001;
    padding: 20px;
    backdrop-filter: blur(4px);
}

.make-call-modal {
    width: 450px;
    background: linear-gradient(145deg, #2a2a2a, #383838);
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    color: #e0e0e0;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.make-call-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
        font-size: 1.25rem;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        letter-spacing: 0.5px;
    }

    .close-btn {
        background: none;
        border: none;
        color: #999;
        font-size: 1.1rem;
        cursor: pointer;
        padding: 8px;
        border-radius: 50%;
        transition: all 0.2s ease;
        
        &:hover {
            // background: rgba(255, 255, 255, 0.1);
            color: #fff;
            transform: rotate(90deg);
        }
    }

    .contact-name {
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.9em;
        color: #666;
    }
}

.make-call-body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.make-call-form-group {
    display: flex;
    flex-direction: column;
    
    width: 100%;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        color: #999;
        letter-spacing: 0.3px;
    }

    input,
    
    select {
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 0.975rem;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        transition: all 0.2s ease;

        &:focus {
            outline: none;
            border-color: rgba(255, 255, 255, 0.3);
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.05);
        }

        &::placeholder {
            color: #666;
        }
    }
    .enterPhone{
        width: calc(100% - 30px);
    }
}

.call-duration-box {
    background: rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    font-family: monospace;
    font-size: 1.5rem;
    color: #fff;
    letter-spacing: 2px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.make-call-action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    margin-top: 1rem;

    .btn {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        display: flex;
        
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        transition: all 0.2s ease;
        background: linear-gradient(145deg, #2a2a2a, #383838);
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
                    -5px -5px 10px rgba(255, 255, 255, 0.05);
        
        &:hover {
            transform: translateY(-2px);
            box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.25),
                        -6px -6px 12px rgba(255, 255, 255, 0.06);
        }

        &:active {
            transform: translateY(0);
            box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2),
                        inset -2px -2px 5px rgba(255, 255, 255, 0.05);
        }
    }

    .btn-primary {
        color: #4CAF50;
    }

    .btn-warning {
        color: #FFC107;
    }

    .btn-danger {
        color: #f44336;
        font-size: 1.75rem;
    }

    .blinking {
        animation: blinkingEffect 1.5s ease infinite;
        color: #f44336;
    }
}

@keyframes blinkingEffect {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

// Add smooth transitions
* {
    transition: background-color 0.2s ease,
                border-color 0.2s ease,
                box-shadow 0.2s ease,
                transform 0.2s ease;
}

.number-selection {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  gap:10px;
  
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    
    input[type="checkbox"] {
      margin-right: 8px;
      width:auto;
      &:disabled + label {
        color: #999;
        font-style: italic;
        
      }
    }
    
    label {
      margin: 0;
    }
  }
}