@keyframes neonGlow {
  0%, 100% { box-shadow: 0 0 5px rgba(66, 153, 225, 0.5), 0 0 10px rgba(66, 153, 225, 0.3); }
  50% { box-shadow: 0 0 20px rgba(66, 153, 225, 0.8), 0 0 30px rgba(66, 153, 225, 0.6); }
}

@keyframes neonOrbPulse {
  0%, 100% { transform: scale(1); opacity: 0.7; }
  50% { transform: scale(1.3); opacity: 1; }
}

@keyframes elegantFloat {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.elegant-ai-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  margin: 20px auto;
  width: fit-content;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  background: linear-gradient(135deg, rgba(66, 153, 225, 0.1), rgba(102, 51, 153, 0.1));
  backdrop-filter: blur(15px);
  border: 1px solid rgba(66, 153, 225, 0.3);
  animation: neonGlow 3s infinite ease-in-out , neonOrbPulse 1.5s infinite ease-in-out , elegantFloat 1.5s infinite ease-in-out;
  transition: all 0.3s ease-in-out;
}

.elegant-ai-indicator .text {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #4299e1;
  letter-spacing: 1px;
  margin-right: 15px;
  z-index: 1;
  text-shadow: 0 0 5px rgba(66, 153, 225, 0.5);
}

.elegant-ai-indicator .orb {
  width: 12px;
  height: 12px;
  background-color: #4299e1;
  border-radius: 50%;
  margin: 0 4px;
  opacity: 0.7;
  animation: neonOrbPulse 1.5s infinite ease-in-out;
  box-shadow: 0 0 5px rgba(66, 153, 225, 0.5);
}

.elegant-ai-indicator .orb:nth-child(1) {
  animation: elegantFloat 1.5s infinite ease-in-out, neonOrbPulse 1.5s infinite ease-in-out;
}

.elegant-ai-indicator .orb:nth-child(2) {
  animation: elegantFloat 1.5s infinite ease-in-out 0.2s, neonOrbPulse 1.5s infinite ease-in-out 0.2s;
}

.elegant-ai-indicator .orb:nth-child(3) {
  animation: elegantFloat 1.5s infinite ease-in-out 0.4s, neonOrbPulse 1.5s infinite ease-in-out 0.4s;
}
  .markdown-content {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .markdown-content h1 {
    font-size: 1.8em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content h2 {
    font-size: 1.5em;
    margin-top: 0.8em;
    margin-bottom: 0.4em;
  }
  
  .markdown-content h3 {
    font-size: 1.2em;
    margin-top: 0.6em;
    margin-bottom: 0.3em;
  }
  
  .markdown-content p {
    margin-bottom: 1em;
  }
  
  .markdown-content ul, .markdown-content ol {
    margin-left: 1.5em;
    margin-bottom: 1em;
  }
  
  .markdown-content li {
    margin-bottom: 0.5em;
  }
  
  .markdown-content code {
    background-color: #2d3748;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
  }
  
  .markdown-content pre {
    background-color: #2d3748;
    padding: 1em;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .markdown-content blockquote {
    border-left: 3px solid #ccc;
    margin: 0;
    padding-left: 1em;
    color: #666;
  }
  .user-message-container {
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    width: 100%;
  }
  .user-avatar-message {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color:  #0084ff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 5px;
  }
  .user-message {
    flex: 1;
    background-color: transparent;
    border-radius: 10px;
    width:100% !important;
    position: relative;
 
  }

  .ai-message-container {
    display: flex;
    align-items: flex-start;
   border-radius: 10px;
   width: 100%;
    
  }
  .ai-avatar-message {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(45deg, #4f9eff, #7c4fff);
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 5px;
  }

  /* @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  } */
  
  .ai-message {
    flex: 1;
    border-radius: 10px;
    padding: 5px 10px;
    overflow: auto;
    width:fit-content;
    width: 95%;
    
  }

  