.inbox-container {
    display: flex;
    flex-direction: column;
    background-color: #1a202c;
    color: #e0e0e0;
    margin-top: 80px;
}

.inbox-main-content {
    padding: 20px;
    transition: margin-left 0.3s ease;
    width: 75vw;
    margin: auto;
}



.inbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #2d3748;
    border-radius: 8px;
    margin-bottom: 20px;
}

.inbox-title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.inbox-title h1 {
    font-size: 1.5rem;
    color: #e0e0e0;
    margin: 0;
}

.inbox-icon {
    color: #fff;
    font-size: 1.5rem;
}



.search-icon {
    color: #b0b0b0;
    margin-right: 10px;
}




.inbox-content {
    background-color: #2d3748;
    border-radius: 8px;
    padding: 20px;
    min-height: 400px;
}

.no-messages {
    text-align: center;
    color: #b0b0b0;
    font-size: 1.1rem;
    margin-top: 40px;
}

.inbox-search-bar {
    display: flex;
    align-items: center;
    background-color: #4a5568;
    padding: 8px 16px;
    border-radius: 6px;
    width: 300px;  
}
.inbox-search-bar input {
    background: transparent;
    border: none;
    color: #e0e0e0;
    width: 100%;
    outline: none;
}


@media (max-width: 768px) {
    .main-content.shifted {
        margin-left: 0;
    }
    
    .search-bar {
        width: 200px;
    }
}
