/* LinkedIn Data Table Styles */
.linkedin-data-table-container {
  width: calc(100% - 20px);
  overflow-x: auto;
  margin: 10px;
  border-radius: 0 0 10px 10px !important;
}

.linkedin-data-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #121010;

}

.linkedin-data-table-title{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  margin-left: 0 !important;
}

.linkedin-data-table th,
.linkedin-data-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #f0f0f0;
  
}

.linkedin-data-table th {
  background-color: #252542;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.linkedin-data-table th:hover {
  background-color: #313155;
}

.linkedin-data-table tr {
  background-color: #1a1a2e;
}

/* .linkedin-data-table tr:hover {
  background-color: #1a1a1a;
} */

.linkedin-data-table a {
  color: #0077b5;
  text-decoration: none;
}

.linkedin-data-table a:hover {
  text-decoration: underline;
}

/* Checkbox styles */
.checkbox-column {
  width: 40px;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #0077b5;
}

/* Table footer styles */
.linkedin-table-footer{
  padding: 12px 15px;
  background-color: #1a1a2e;
  border-top: 1px solid #444;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  border-radius: 0 0 10px 10px;
}

.table-info {
  display: flex;
  gap: 20px;
  color: #f0f0f0;
}

/* Pagination styles */
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ccc;
  background-color: #1a1a2e;
}

.pagination-button {
  padding: 5px 10px;
  border: 1px solid #444;
  background-color: #2c2c2c;
  color: #ccc;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.pagination-button:hover:not(:disabled) {
  background-color: #313155;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background-color: #1a1a1a;
}

.pagination-info {
  padding: 0 10px;
}

/* Enrich button styles */
.enrich-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #0077b5;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.enrich-button:hover:not(.disabled) {
  background-color: #005582;
}

.enrich-button.disabled {
  background-color: #1a1a1a;
  cursor: not-allowed;
  opacity: 0.7;
  border: 1px solid #444;
}

/* Responsive design */
@media screen and (max-width: 600px) {
  .linkedin-data-table {
    font-size: 12px;
  }

  .linkedin-data-table th,
  .linkedin-data-table td {
    padding: 8px 10px;
  }



  .pagination {
    order: 2;
  }

  .table-info {
    order: 1;
    flex-direction: column;
    gap: 5px;
  }

  .enrich-button {
    order: 3;
    width: 100%;
  }
}

/* Custom tooltip for full text */
.linkedin-data-table td[title] {
  position: relative;
}

.linkedin-data-table td[title]:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #2c2c2c;
  color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 3px;
  z-index: 1000;
  white-space: normal;
  max-width: 300px;
  word-wrap: break-word;
  border: 1px solid #444;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Profile link style */
.linkedin-data-table td a {
  display: inline-block;
  padding: 4px 8px;
  background-color: #0077b5;
  color: white;
  border-radius: 3px;
  transition: background-color 0.3s;
  text-align: center;
}

.linkedin-data-table td a:hover {
  background-color: #005582;
  text-decoration: none;
}

