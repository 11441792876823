/* JobTable.css */
.job-table-wrapper {
    overflow: auto !important;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px !important;
  }
  
  .job-results-table {
    overflow: auto !important;
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    color: #ffffff;
    border-radius: 10px !important;
    table-layout: fixed; /* Ensures consistent column widths */
  }
  
  .job-results-table th,
  .job-results-table td {
    border: 1px solid #444;
    padding: 8px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Ensures text doesn't wrap */
  }
  
  .job-results-table th {
    background-color: #252542;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .job-results-table th:hover {
    background-color: #313155;
  }
  
  .job-results-table tr {
    background-color: #1a1a2e;
  }
  
  /* Column width specifications */
  .job-results-table th:nth-child(1),
  .job-results-table td:nth-child(1) {
    width: 15%; /* Source column */
  }
  
  .job-results-table th:nth-child(2),
  .job-results-table td:nth-child(2) {
    width: 20%; /* Company column */
  }
  
  .job-results-table th:nth-child(3),
  .job-results-table td:nth-child(3) {
    width: 25%; /* Title column */
  }
  
  .job-results-table th:nth-child(4),
  .job-results-table td:nth-child(4) {
    width: 20%; /* Location column */
  }
  
  .job-results-table th:nth-child(5),
  .job-results-table td:nth-child(5) {
    width: 20%; /* View Job column */
  }
  
  .job-cell-content p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .job-link-button {
    background-color: #0077b5;
    padding: 6px 12px;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: inline-block;
  }
  
  .job-link-button:hover {
    background-color: #086ca1;
  }
  
  /* Mobile responsiveness */
  @media screen and (max-width: 768px) {
    .job-results-table {
      font-size: 12px;
    }
  
    .job-results-table th,
    .job-results-table td {
      padding: 8px;
    }
  
    .job-link-button {
      padding: 4px 8px;
    }
  }
  
  /* Optional: Add scrollbar styling for the table wrapper */
  .job-table-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .job-table-wrapper::-webkit-scrollbar-track {
    background: #1a1a1a;
    border-radius: 4px;
  }
  
  .job-table-wrapper::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 4px;
  }
  
  .job-table-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }