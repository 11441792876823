.company-table-container {
    overflow: auto !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  table {
    border-radius: 10px !important;
  }
  
  .company-table th,
  .company-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .company-table th {
    background-color: #252542;
    font-weight: bold;
  }
  
  .company-table tr:nth-child(even) {
    background-color: #1a1a2e;
  }
  
  .company-table {
    overflow: auto !important;
    border-collapse: collapse;
    font-size: 14px;
    color: #ffffff;
    width: 100%;
  }
  
  .company-table th,
  .company-table td {
    border: 1px solid #444;
    padding: 8px;
    text-align: left;
  }
  
  .company-table th {
    background-color: #252542; 
    font-weight: bold;
  }
  
  .company-table tr {
    background-color: #1a1a2e; 
  }
  
  
  .company-table a {
    color: #4a90e2; 
    text-decoration: none;
  }
  
  .company-table a:hover {
    text-decoration: underline;
  }
  
  .company-filter-input {
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
  }
  
  .company-table th {
    cursor: pointer;
  }
  
  .company-table th:hover {
    background-color: #313155;
  }
  