.signal-type-selector {
    max-width: 400px;
    width: 90%;
  }
  
  .signal-type-options {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    margin-top: 0 !important;
  }
  
  .signal-type-button {
    padding: 12px 20px;
    background-color: #2d2d4f;
    border: 1px solid #4a90e2;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .signal-type-button:hover {
    background-color: #4a90e2;
    transform: translateY(-2px);
  }

  .signal-type-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    margin-left: auto !important;
    min-height: 150px;
  }

  .signal-type-submit-button {
    width: 100px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .signal-type-submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .signal-type-checkbox {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }

  .signal-type-checkbox input[type="checkbox"] {
    margin-right: 10px;
  }

  .signal-type-checkbox label {
    cursor: pointer;
  }
  .signal-type-title{
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
  .signal-type-submit-button-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }