.send-email-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10001;
    padding: 20px;
  }
  
  .send-email-modal {
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 850px;
    color: #ccc;
    overflow: auto;
    max-height: 85vh;
  }
  
  .send-email-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #444;
    padding: 1rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  
    h2 {
      font-size: 1.2rem;
      color: #f0f0f0;
      margin: 0;
      margin-left: 10px !important;
    }
  
    .close-btn {
      background: none;
      border: none;
      color: #f0f0f0;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
  
  .send-email-body {
    padding: 1rem 1.5rem 8px 1.5rem;
    position: relative;
  
    .send-email-form-groups {
      margin-bottom: 1rem;
  
      label {
        display: block;
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: #f0f0f0;
      }
  
      input,
      select {
        width: 97.55%;
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 4px;
        border: 1px solid #ccc;
        background-color: #444;
        color: #ccc;
      }
    }
  
    .recipients-container {
      margin-bottom: 1rem;
  
      .recipients-container-child {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 15px;
        max-height: 5rem;
        overflow-y: auto;
      }
  
      .chip {
        display: flex;
        align-items: center;
        background-color: #0275DF;
        padding: 0.3rem 0.6rem;
        border-radius: 16px;
        color: #fff;
        font-size: 0.9rem;
  
        .chip-close-btn {
          background: none;
          border: none;
          color: #fff;
          font-size: 1.1rem;
          margin-left: 0.5rem;
          cursor: pointer;
        }
      }
  
      input {
        flex: 1;
        border: none;
        outline: none;
        background-color: #444;
        color: #ccc;
        padding: 0.5rem;
        border-radius: 4px;
      }
    }
  }
  
  .select-sender-dropdown {
    width: 40% !important;
  }
  
  .send-email-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
  
    .cancel-btn,
    .send-btn {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 4px;
      cursor: pointer;
      border: none;
      transition: background-color 0.3s ease;
  
      &.cancel-btn {
        background-color: #e74c3c;
        color: #fff;
  
        &:hover {
          background-color: #c0392b;
        }
      }
  
      &.send-btn {
        background-color: #007bff;
        color: #fff;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  
  .quill-send-message-group {
    .ql-container {
      max-height: 12rem;
      overflow-y: auto;
    }
  }