.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1a202c;
  padding: 10px 20px;
  z-index: 1000; /* Reduced z-index */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.navbar-header {
  background: linear-gradient(45deg, #4f9eff, #7c4fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #4a90e2; /* Fallback color for unsupported browsers */
  margin: 0;
  font-size: 24px;
  z-index: 9999999999;
}

.star-button {
  background: none;
  border: none;
  color: #ffa500;
  font-size: 20px;
  cursor: pointer;
  padding: 6px;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.star-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Lighter hover effect */
}

.star-button .fa-star {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.star-button:hover .fa-star {
  transform: rotate(90deg);
}

.star-button .starred {
  color: #ffa500;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.menu-icon {
  margin-left: 15px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  color: #7c4fff;
}

.navbar-transparent {
  background-color: transparent !important;
}