/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
}

.content-container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 20px;
  background: #1a1a2e;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.content-main-content{
  padding: 10px;
  padding-top: 0;
}

.content-title {
  font-size: 2.2em;
  font-weight: 700;
  background: linear-gradient(45deg, #4a90e2, #67b1f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.content-section {
  margin-bottom: 30px;
  background-color: #252542;
  border-radius: 8px;
  padding: 20px;
}

.content-section-title {
  font-size: 1.5em;
  font-weight: 600;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 0;
  color: #34495e;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #4a90e2, #67b1f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content-section-content p {
  font-size: 1em;
  color: #ccc;
  margin-bottom: 10px;
}

/* Action Buttons */
.content-display-action-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  background: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background 0.3s ease;
}

.action-button:hover {
  background: #2980b9;
}

/* Dropdown Menus */
.content-dropdown-container {
  position: relative;
}

.content-dropdown-menu {
  position: absolute;
  top: 102%;
  right: 0;
  background: #2980b9;
  border: 1px solid #2980b9;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 180px;
}

.content-dropdown-menu button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #ccc;
  transition: background 0.3s ease;
}

.content-dropdown-menu button:hover {
  background: #252542;
  border-radius: 5px;
}
