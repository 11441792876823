.test-container {
    width: calc(100% - 30px);
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px;
  }
  
  .test-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .test-tab-list {
    display: flex;
    border-bottom: 1px solid #ddd;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  
  .test-tab-button {
    padding: 12px 24px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    position: relative;
    transition: color 0.3s;
  }
  
  .test-tab-button:hover {
    color: #2563eb;
  }
  
  .test-tab-button.active {
    color: #2563eb;
    border-bottom: 2px solid #2563eb;
  }
  
  .test-content-area {
    padding: 16px;
  }
  
  .test-textarea {
    width: calc(100% - 35px);
    height: 300px;
    padding: 16px;
    font-family: monospace;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    background-color: #fafafa;
  }
  
  /* Added some responsive styles */
  @media (max-width: 768px) {
    .test-container {
      padding: 8px;
    }
    
    .test-tab-button {
      padding: 8px 16px;
      font-size: 12px;
    }
    
    .test-textarea {
      width: 95%;
      height: 200px;
    }
  }