.funding-dashboard {
  max-width: 1200px;
  margin: 15px auto;
  padding: 2rem;
  background-color: #1a1a2e;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.funding-header {
  margin-bottom: 2rem;
}

.funding-title {
  font-size: 2rem;
  background: linear-gradient(45deg, #4a90e2, #67b1f7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.funding-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.summary-card {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background-color: #252545;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.summary-icon {
  font-size: 1.5rem;
  color: #3498db;
  margin-right: 1rem;
}

.summary-content {
  display: flex;
  flex-direction: column;
}

.summary-label {
  font-size: 0.875rem;
  color: #ccc;
  margin-bottom: 0.25rem;
}

.summary-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #ccc;
}

.funding-content {
  display: grid;
  gap: 2rem;
}

.content-section {
  background-color: 252542;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.section-icon {
  font-size: 1.5rem;
  color: #3498db;
  margin-right: 0.75rem;
}

.section-title {
  font-size: 1.5rem;
  color: #ccc;
  font-weight: 600;
  margin: auto auto auto 0 !important;
}

.investment-grid,
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.investment-card,
.product-card {
  background-color: #1a1a2e;
  border-radius: 8px;
  padding: 1.25rem;
  transition: transform 0.2s ease;
}

.investment-card:hover,
.product-card:hover {
  transform: translateY(-2px);
}

.investment-target,
.product-name {
  font-size: 1.25rem;
  color: #ccc;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.investment-amount {
  font-size: 1.5rem;
  color: #27ae60;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.investment-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #ccc;
}

.investment-link,
.product-link {
  display: inline-block;
  padding: 0.5rem 1rem;
 
  border: 1px solid #3498db;
  color: #3498db;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.investment-link:hover,
.product-link:hover {
  background-color: #2980b9;
  color: #fff;
}

.product-date {
  font-size: 0.875rem;
  color: #ccc;
  margin-bottom: 1rem;
}

.show-more-btn {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: transparent;
 
  color: #3498db;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.show-more-btn:hover {
  text-decoration: underline;
  color: #4a90e2;
}

@media (max-width: 768px) {
  .funding-dashboard {
    padding: 1rem;
  }

  .investment-grid,
  .product-grid {
    grid-template-columns: 1fr;
  }

  .funding-title {
    font-size: 1.5rem;
  }
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding: 2rem;
  background-color: #252542;
  border-radius: 8px;
  margin: 2rem 0;
}

.empty-state-message {
  font-size: 1.2rem;
  color: #ccc;
  text-align: center;
} 