.review-sequence-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
}

.review-sequence-modal {
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 850px;
    color: #ccc;
}

.review-sequence-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #444;
    padding: 1rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.review-sequence-header h2 {
    font-size: 1.2rem;
    color: #f0f0f0;
    margin: 0;
    margin-left: 10px !important;
}

.header-highlight {
    color: #87CEEB;
}

.close-btn {
    background: none;
    border: none;
    color: #f0f0f0;
    font-size: 1.2rem;
    cursor: pointer;
}

.review-sequence-body {
    padding: 0 1.5rem 1.5rem 1.5rem;
    position: relative;
}

.review-sequence-info {
    margin-bottom: 1rem;
}

.sequence-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-sequence-info p {
    margin: 0.7rem 0;
    font-size: 1rem;
    display: flex;
    /* justify-content: space-between; */
    gap: 5px; 
}

.highlight {
    color: #87CEEB;
}

.review-sequence-navigation {
    position: absolute;
    top: 0px;
    right: 10px;
    display: flex;
    gap: 0.5rem;
}

.review-sequence-navigation button {
    background: none;
    border: none;
    color: #ccc;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 25px;
}

.review-sequence-editor {
    margin: 1.5rem 0;
}

.review-sequence-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.cancel-btn,
.schedule-btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
}

.cancel-btn {
    background-color: #e74c3c;
    color: #fff;
}

.schedule-btn {
    background-color: #007bff;
    color: #fff;
}

.cancel-btn:hover {
    background-color: #c0392b;
}

.schedule-btn:hover {
    background-color: #0056b3;
}

.ql-disabled{
    max-height: 15rem;
    overflow-y: auto;
}