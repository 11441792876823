/* SignalAutomationPopup.css */
.signal-automation-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .signal-automation-form-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
  }
  
  .signal-automation-popup-content {
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .signal-automation-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #666;
  }
  
  .signal-automation-close-button:hover {
    color: #db3030;
  }
  
  .signal-automation-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    margin: 0 !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .signal-automation-form-group {
    margin-bottom: 25px;
    position: relative;
    margin-top: 10px; 
  }
  
  .signal-automation-form-group label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #333;
    padding: 0 5px;
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #ccc;
  }
  
  .signal-automation-input,
  .signal-automation-textarea {
    width: calc(100% - 20px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #ccc;
    background-color: #333;
    
  }
  .signal-automation-select-option{
    color: #555 !important;
  }
  .signal-automation-select{
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #ccc;
    background-color: #333;
  }
  
  .signal-automation-textarea {
    resize: vertical;
    min-height: 100px;
  }
  .signal-automation-submit-button-container{
    display: flex;
    justify-content: flex-end;
   
  }
  
  .signal-automation-submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
  }
  
  .signal-automation-submit-button:hover {
    background-color: #0056b3;
  }
  
  .signal-automation-left-column{
    width: 50%;
    border-right: 1px solid #ccc;
    padding-right: 20px;
  }
  .signal-automation-right-column{
    width: 50%;
  }
  

  .signal-automation-emails-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    background-color: transparent;
}

.signal-automation-emails-count {
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
}

.signal-automation-emails-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 10px;
}

.signal-automation-email-item {
    display: flex;
    align-items: center;
    background-color: #0056b3;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
    color: #fff;
}

.signal-automation-remove-button {
    background: none;
    border: none;
    color: #ccc;
    margin-left: 8px;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    line-height: 1;
}

.signal-automation-remove-button:hover {
    color: #ff0000; /* Red color on hover */
}

.signal-automation-add-email {
    display: flex;
    align-items: center;
    gap: 10px;
}

.signal-automation-add-email input {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
}

.signal-automation-add-email input:focus {
    border-color: #007bff;
}
.signal-automation-emails-count{
    font-size: 14px;
    margin-bottom: 10px;
    color: #fff;
    margin-top: 10px;
}
.signal-automation-emails-count strong{
    
    margin-right: 5px;
}