.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1e2130;
}

.login-card {
    background-color: #2c3143;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 350px;
}

.app-title {
    color: #4285f4;
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    margin-top: 0;
}

.login-heading {
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
}

.login-inputs {
    margin-bottom: 30px;
}

.input-container {
    margin-bottom: 20px;
}

.input-container label {
    display: block;
    color: #fff;
    margin-bottom: 5px;
}

.input-container label span {
    font-size: 0.8em;
    color: #8e8e8e;
}

.input-container input, .password-input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid transparent;
    background-color: #3a3f51;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    
}

.input-container input:focus, .password-input:focus-within {
    outline: none;
    border-color: #4285f4;
}


.password-input {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0px;
    width: calc(100% - 10px);
}

.password-input input {
    flex-grow: 1;
    border: none;
    background: none;
}

.password-input input:focus {
    outline: none;
    
}

.eye-icon {
    color: #8e8e8e;
    cursor: pointer;
    margin-left: 10px;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #3367d6;
}

.forgot-password {
    display: block;
    text-align: center;
    color: #4285f4;
    text-decoration: none;
    margin-top: 15px;
}

.signup-prompt {
    text-align: center;
    color: #fff;
    margin-top: 20px;
}

.sign-up {
    color: #4285f4;
    text-decoration: none;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #4285f4;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    color: #ff4d4f;
    text-align: center;
    margin-top: 10px;
}

.success-message {
    color: #52c41a;
    text-align: center;
    margin-top: 10px;
}