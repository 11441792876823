.people-table-container {
  overflow: auto !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.people-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #ccc;
  border-radius: 10px !important;
  table-layout: fixed;
}

.people-table th,
.people-table td {
  border: 1px solid #444;
  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.people-table th {
  background-color: #252542;
  font-weight: bold;
  cursor: pointer;
}

.people-table th:hover {
  background-color: #313155;
}

.people-table tr {
  background-color: #1a1a2e;
}

/* Column width specifications */
.people-table th:first-child,
.people-table td:first-child {
  width: 5%;  /* Checkbox column - small */
  text-align: center;
}

.people-table th:nth-child(2),
.people-table td:nth-child(2) {
  width: 15%;  /* Name column - larger */
}

.people-table th:nth-child(3),
.people-table td:nth-child(3){
  width: 15%;  /* Email column */
}
.people-table th:nth-child(4),
.people-table td:nth-child(4) {
  width: 10%;  /* Title and Company columns */
}

.people-table th:nth-child(5),
.people-table td:nth-child(5) {
  width: 15%;  /* Location column */
}

.people-table th:nth-child(6),
.people-table td:nth-child(6) {
  width: 10%;  /* LinkedIn column */
}

.people-table input[type="checkbox"] {
  cursor: pointer;
}

.people-table .selected-row {
  background-color: #2c3e50;
}

.people-table .selected-row:hover {
  background-color: #34495e;
}

.people-table .name-header-content {
  display: flex;
  align-items: center;
}

.people-table .name-header-content span {
  cursor: pointer;
}

.people-table .name-header-content .sort-indicator {
  margin-left: 5px;
}

/* Styling for selected rows */
.people-table .contact-checkbox {
  cursor: pointer;
}

.people-table .linkedin-link {
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
  background-color: #3983dd;
  padding: 5px 10px;
  border-radius: 5px;
}

.people-table .linkedin-link:hover {
  background-color: #2378df;
}

.table-footer {
  padding: 12px 15px;
  background-color: #1a1a2e !important;
  border-top: 1px solid #444;
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
  color: #ccc;
  border-radius: 0 0 10px 10px;
}

.enrichment-stats {
  display: flex;
  gap: 10px;
}

.stats-divider {
  margin: 0 5px;
}

.save-section {
  margin-left: auto ;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.save-to-button {
  padding: 5px 10px;
  background-color: #4a90e2 !important;
  color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-left: auto;
}

.save-to-button:hover {
  background-color: #3983dd;
}

.save-to-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.success-message {
  display: flex;
  align-items: center;
  color: #28a745;
  font-size: 14px;
  margin-bottom: 10px;
}

.enrich-selected-btn{
  padding: 5px 10px;
  background-color: #4a90e2 !important;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-left: auto;
}
.opacity-50{
  margin-left: auto;
  background-color: #1a1a1a;
  color: #ccc;
  border: 1px solid #444;
  border-radius: 4px;
  cursor: not-allowed;
  
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 16px 0;
  color: #ccc;
}

.pagination-btn {
  padding: 0;
  border: none;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  color: #ccc;
  font-size: 1.5rem;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-btn.active {
  background: #007bff;
  color: #ccc;
  border-color: #007bff;
}

.table-footer-left{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;

}

.table-footer-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.table-footer-left button{
  height: 100%;
} 
.pagination-controls-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0;
  padding: 0;
}
.sales-navigator-table-footer{
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0;
}
.sales-navigator-table-footer p{
  margin: 0;
}
.sales-navigator-pagination-controls{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0 !important;
  padding: 0;
}
.sales-navigator-search-input{
  width: calc(100% - 22px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #1a1a2e;
  color: #ccc;
  margin-bottom: 10px;
}

.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
}

.image-modal {
    max-width: 90vw;
    max-height: 90vh;
    animation: zoomIn 0.3s ease-out;
}

.image-modal-content {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
}

@keyframes zoomIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}