:root {
    --primary-color: #4a90e2;
    --primary-dark: #42a5f5;
    --accent-color: #ffd700;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --bg-color: #1a202c;
    --card-bg: #2d3748;
    --text-color: #e0e0e0;
    --text-secondary: #b0b0b0;
    --border-color: #404040;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --message-user-bg: #000;
    --message-ai-bg: #2d3748;
    --input-bg: #4a5568;
    --radius: 8px;
    --transition: all 0.3s ease;
    --color: white;
  }
  
  .dark {
    --primary-color: #4a90e2;
    --primary-dark: #42a5f5;
    --bg-color: #1a1a1a;
    --card-bg: #2d2d2d;
    --text-color: #e0e0e0;
    --text-secondary: #b0b0b0;
    --border-color: #404040;
    --shadow-color: rgba(0, 0, 0, 0.3);
    --message-user-bg: #4a90e2;
    --message-ai-bg: #3d3d3d;
    --input-bg: #3d3d3d;
    --color: white;
  }
  
  /* Action Point Container */
  .action-point-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--bg-color);
    color: var(--text-color);
    padding: 20px;
 
  }
  .action-point-container-inner{
    margin: 50px 50px;
    padding: 1rem;
  }
  
  /* Welcome Text */
  .welcome-text {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .welcome-text h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .welcome-text p {
    font-size: 1.2rem;
    color: var(--text-secondary);
    margin-bottom: 20px;
  }
  
  /* Navigation Bar */
  .action-point-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin: 1.25rem 0 2.5rem;
    width: 100%;
  }
  
  .action-point-nav button {
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 1rem;
    border-radius: 0.625rem;
    opacity: 0.6;
    position: relative;
    overflow: hidden;
  }
  
  .action-point-nav button.active {
    opacity: 1;
    border-color: var(--primary-color);
    color: var(--color);
    box-shadow: 0 2px 8px rgba(74, 144, 226, 0.2);
  }
  
  .action-point-nav button:hover {
    transform: translateY(-2px);
    opacity: 1;
    background-color: rgba(74, 144, 226, 0.1);
  }
  
  .action-point-nav button.active:hover {
    transform: none;
    background-color: transparent;
  }
  
  /* Content Types Section */

  .action-point-types-container {
    position: relative;
    margin: 2rem 0;
    padding: 0 30px 0 30px; /* Space for arrows */
}

.action-point-scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--card-bg);
    border: 1px solid var(--border-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer ;
    z-index: 100;
    box-shadow: 0 2px 4px var(--shadow-color);
}

.action-point-scroll-button:hover {
    background: var(--bg-color);
}

.action-point-scroll-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.action-point-scroll-left {
    left: -10px;
}

.action-point-scroll-right {
    right: -10px;
}
.action-point-content-types-wrapper {
    position: relative;
    overflow: hidden;
}
.action-point-content-types-grid-container {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%; /* Space for arrows */
}
.action-point-content-types-page {
    display: grid;
    
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
    min-width: calc(100% - 2rem);
    padding: 1rem;
    
}
.action-point-content-type-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background: var(--card-bg);
    /* border: 2px solid var(--border-color); */
    border-radius: var(--radius);
    cursor: pointer;
    /* transition: var(--transition); */
    color: var(--color);
    width: 280px;
    border: 2px solid transparent;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    
}

.action-point-content-type-btn::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    transition: all 0.4s ease;
    opacity: 0;
    box-shadow: 0 0 0 2px var(--primary-color);
}

.action-point-content-type-btn:hover {
    transform: scale(1.03) translateY(-4px);
    box-shadow: 0 8px 20px rgba(74, 144, 226, 0.2),
    0 4px 12px rgba(74, 144, 226, 0.1);
}

.action-point-content-type-btn:hover .action-point-content-type-icon {
    transform: scale(1.1);
    color: var(--primary-color);
}

.action-point-content-type-btn:hover::after {
    opacity: 1;
    inset: -4px;
}

.action-point-content-type-btn:hover .action-point-content-type-icon {
    animation: pulse 1s infinite;
}
.action-point-content-type-icon {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.action-point-content-type-name {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.action-point-content-type-description {
    font-size: 0.9rem;
    color: var(--text-secondary);
    text-align: center;
}
/* Responsive grid layouts */
@media (min-width: 1400px) {
    .action-point-content-types-grid {
        grid-template-columns: repeat(4, 1fr); /* 4 buttons per row on large screens */
    }
    .action-point-content-type-btn {
        width: 100%;
    }
    .action-point-content-types-page {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1399px) and (min-width: 1000px) {
    .action-point-content-types-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 buttons per row on medium screens */
    }
    .action-point-content-type-btn {
        width: 100%;
    }
    .action-point-content-types-page {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 999px) {
    .action-point-content-types-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 buttons per row on small screens */
    }
    .action-point-content-type-btn {
        width: 100%;
    }
    .action-point-content-types-page {
        grid-template-columns: repeat(2, 1fr);
    }
}

.selected-type-view {
    padding: 20px;
    margin-top: 0;
}

.selected-type-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 1px solid #444;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    color:#ccc;
}

.selected-type-title {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 20px;
    margin-bottom: 0 ;
}

.selected-type-icon {
    font-size: 24px;
}

.content-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
}

.content-card {
    min-width: 200px;
    max-width: 300px;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--card-bg);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    border: 2px solid transparent;
    overflow: hidden ;
    position: relative !important;
}

.content-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    border: 2px solid var(--primary-color);
}
.action-card-buttons-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--card-bg);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transform: translateY(100%);
    transition: all 2s ease !important;
    border-radius: var(--radius);
    z-index: 100;
}


.content-card:hover .action-card-buttons-container {
    transform: translateY(0%) !important;
}
.action-card-preview-button,
.action-card-start-button {
    padding: 8px 12px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-size: 14px;
}

.action-card-preview-button {
    background-color: var(--primary-color);
    color: white;
}

.action-card-start-button {
    background-color: #1a202c;
    color: white;
    padding: 10px 20px;
}


@media (max-width: 768px) {
    .content-card {
        flex: 0 0 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .content-card {
        flex: 0 0 100%;
    }
}