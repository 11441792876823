.enriched-table-container {
  overflow: auto !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.enriched-table {
  overflow: auto !important;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #ffffff;
  border-radius: 10px !important;
  table-layout: fixed;
}

.enriched-table th,
.enriched-table td {
  border: 1px solid #444;
  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.enriched-table th {
  background-color: #252542;
  font-weight: bold;
  cursor: pointer;
}

.enriched-table th:hover {
  background-color: #313155;
}

.enriched-table tr {
  background-color: #1a1a2e;
}

/* Column width specifications */
.enriched-table th:first-child,
.enriched-table td:first-child {
  width: 5%;  /* Checkbox column - small */
  text-align: center;
}

.enriched-table th:nth-child(2),
.enriched-table td:nth-child(2) {
  width: 15%;  /* Name column - larger */
}

.enriched-table th:nth-child(3),
.enriched-table td:nth-child(3),
.enriched-table th:nth-child(6),
.enriched-table td:nth-child(6){
  width: 15%;
}
.enriched-table th:nth-child(4),
.enriched-table td:nth-child(4),
.enriched-table th:nth-child(5),
.enriched-table td:nth-child(5) {
  width: 10%;  /* Title, Company, Location columns - equal */
}


.enriched-table th:nth-child(7),
.enriched-table td:nth-child(7),
.enriched-table th:nth-child(8),
.enriched-table td:nth-child(8) {
  width: 12%;  /* Email, Phone, LinkedIn columns */
}

.enriched-table input[type="checkbox"] {
  cursor: pointer;
}

.enriched-table .selected-row {
  background-color: #2c3e50;
}

.enriched-table .selected-row:hover {
  background-color: #34495e;
}

.enriched-table .name-header-content {
  display: flex;
  align-items: center;
}

.enriched-table .name-header-content span {
  cursor: pointer;
}

.enriched-table .name-header-content .sort-indicator {
  margin-left: 5px;
}

/* Styling for enriched rows */
.enriched-row {
  background-color: #2c3e50;
}

.enriched-table .contact-checkbox {
  cursor: pointer;
}

.enriched-table .locked-content {
  color: red;
}

.enriched-table .linkedin-link {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
  background-color: #3983dd;
  padding: 5px 10px ;
  border-radius:  5px
}

.enriched-table .linkedin-link:hover {
  background-color: #2378df;
}

.table-footer {
  padding: 12px 15px;
  background-color: #121010;
  border-top: 1px solid #444;
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
  color: #ccc;
  border-radius: 0 0 10px 10px;
}
.enrichment-table-footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
  color: #ccc;
}

.enrichment-stats {
  display: flex;
  gap: 10px;
}

.stats-divider {
  margin: 0 5px;
}

.save-section {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.save-to-button {
  padding: 5px 10px;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.save-to-button:hover {
  background-color: #3983dd;
}

.save-to-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #1a1a1a !important;
  color: #ccc;
  border: 1px solid #444;
}

.success-message {
  display: flex;
  align-items: center;
  color: #28a745;
  font-size: 14px;
  margin-bottom: 10px;
}

.email-cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.validate-email-btn {
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #2378df;
  background-color: #2378df;
  cursor: pointer;
  transition: all 0.2s;
  width: fit-content;
  color: white;
}

.validate-email-btn:hover {
  background-color: #2378df;
}

.validate-email-btn.validated {
  background-color: transparent;
  color: #28a745;
  border: none;
  font-size: 14px;
}
.validate-email-btn.invalid {
  background-color: transparent; /* Red background for invalid emails */
  color: white;
  border-color: #28a745;
}
.name-cell{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.people-list-profile-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #374151
}