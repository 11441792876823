.research-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.research-modal {
    background-color: #252545;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.research-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.research-modal-header h2 {
    margin: 0;
    margin-left: 0 !important;
    font-size: 1.25rem;
    font-weight: 600;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.contact-tabs {
    display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #3a3a5f;
}

.contact-tab-button {
    padding:10px 0;
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.contact-tab-button.active {
    color: #00a0dc;
  border-bottom: 2px solid #00a0dc !important;
}

.contact-research-content {
    padding: 20px;
  
    margin-bottom: 15px;
}

.peer-item {
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.contact-research-section {
    background-color: #252545;
    border-radius: 8px;
    padding: 0;
}

.contact-research-section h3 {
    color: #00a0dc;
    margin-bottom: 12px;
    margin-top: 0;
    font-size: 1.1em;
    font-weight: 600;
}

.contact-research-section p {
    color: #ccc;
    line-height: 1.6;
    margin: 0;
    margin-bottom: 12px;
}

.contact-research-content {
    max-height: 60vh;
    overflow-y: auto;
    padding: 0 12px;
}

/* Add these styles for the loader */
.research-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 20px;
}

.loader-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

.research-loader p {
    color: #4a5568;
    font-size: 1.1em;
    margin: 0;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} 