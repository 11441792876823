/* Job Change Component Styles */
.job-change-container {
    background-color: #252542;
    border-radius: 12px;
    padding: 2rem;
    margin-top: 1.5rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .job-change-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  }
  
  .job-change-no-changes-message {
    color: #ffffff;
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    opacity: 0.8;
  }
  
  .no-changes-icon {
    font-size: 3rem;
    color: #4a90e2;
    margin-bottom: 1rem;
  }
  
  .job-change-summary-section,
  .job-change-changes-section {
    background: #1a1a33;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .job-change-section-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }
  
  .section-icon {
    color: #4a90e2;
    font-size: 1.5rem;
  }
  
  .job-change-section-header h3 {
    color: #4a90e2;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
  }
  
  .job-change-summary-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
  }
  
  .job-change-stat-item {
    background: #252542;
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: transform 0.3s ease;
  }
  
  .job-change-stat-item:hover {
    transform: scale(1.05);
  }
  
  .job-change-stat-icon {
    color: #4a90e2;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .job-change-stat-content {
    display: flex;
    flex-direction: column;
  }
  
  .job-change-stat-label {
    color: #ffffff;
    font-size: 0.9rem;
    opacity: 0.7;
    margin-bottom: 0.3rem;
  }
  
  .job-change-stat-value {
    color: #4a90e2;
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .job-change-item {
    background: #252542;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
  }
  
 
  
  .job-change-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #3a3a5f;
  }
  
  .job-change-event-type {
    color: #ccc;
    font-weight: 700;
    font-size: 1.1rem;
  }
  
  .job-change-date {
    color: #ffffff;
    opacity: 0.8;
    font-size: 0.9rem;
  }
  
  .job-change-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .job-change-position-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .job-change-previous-details,
  .job-change-current-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #1a1a33;
    padding: 1rem;
    border-radius: 10px;

  }
  .job-change-previous-details:hover,
  .job-change-current-details:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
  
  .job-change-previous-details h4,
  .job-change-current-details h4 {
    color: #ffffff;
    font-size: 1.1rem;
    margin: 0;
    opacity: 0.8;
    font-weight: 600;
  }
  
  .job-change-job-title {
    color: #4a90e2;
    font-weight: 600;
    font-size: 1.2rem;
  }
  
  .job-change-company-name {
    color: #ffffff;
    opacity: 0.9;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .job-change-details {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  
    .job-change-previous-details,
    .job-change-current-details {
      padding-bottom: 1.5rem;
      gap: 0.75rem;
    }
  
    .job-change-previous-details:not(:last-child) {
      border-bottom: 1px solid #3a3a5f;
    }
  
    .job-change-summary-stats {
      grid-template-columns: 1fr;
    }
  }